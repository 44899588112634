<template>
  <div>
    <b-form-file v-model="file1" :state="Boolean(file1)" :placeholder="this.$i18n.t('global.dragDrop')" ref="file-input" class="custom-input" type="file" accept=".jasper"></b-form-file>
    <div class="mt-3">{{ this.$i18n.t("patient.attachments.fileSelected") }} {{ file1 ? file1.name : "" }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file1: null,
      pdf: null,
    };
  },
  watch: {
    file1: function (e) {
      let me = this;
      if (e !== null) {
        const selectedFile = e;
        me.jsonData.name = e.name;
        this.createBase64(selectedFile);
      }
    },
  },
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  methods: {
    createBase64(fileObject) {
      let me = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        me.pdf = e.target.result;
        me.jsonData.base64 = me.pdf;
        this.$emit("update", me.jsonData);
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>

<style></style>
