<template>
  <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <protocollo-moduli-list-component ref="ProtocolloModuliListComponent" @afterLoadData="onAfterLoadData"></protocollo-moduli-list-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ProtocolloModuliListComponent from "../components/ProtocolloModuliListComponent.vue";

export default {
  components: { SaPageLayout, ProtocolloModuliListComponent },
  data() {
    return {
      btnNewVisible: false,
      pathResource: "",
      linkedit: "/protocolmodules",
      showModalLoading: false,
      listKey: 0,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.ProtocolloModuliListComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
