<template>
    <data-analysis-dashboard-view-component></data-analysis-dashboard-view-component>
</template>

<script>
import DataAnalysisDashboardViewComponent from "../components/DataAnalysisDashboardViewComponent.vue";
export default {
    components: { DataAnalysisDashboardViewComponent },
};
</script>

<style></style>
