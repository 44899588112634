<template>
    <sa-page-layout :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
      <template slot="table-body">
        <div class="sa-tab-scroll">
          <gedoc-protocollo-view-component @showmodalloading="onShowModalLoading" @update="onUpdateJsonData"></gedoc-protocollo-view-component>
        </div>
      </template>
    </sa-page-layout>
  </template>
  
  <script>
  import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
  import GedocProtocolloViewComponent from "../components/GedocProtocolloViewComponent.vue";
  export default {
    components: { SaPageLayout, GedocProtocolloViewComponent },
    data() {
      return {
        linkback: "/gedocprotocollinotification",
        pathResource: "/notificationgedocresponse",
        showModalLoading: false,
        id: null,
        jsonData: {},
      };
    },
    mounted() {
      let me = this;
      me.id = this.$route.params.id;
    },
    methods: {
      onShowModalLoading(value) {
        let me = this;
        me.showModalLoading = value;
      },
      onUpdateJsonData(data) {
        let me = this;
        me.jsonData = data;
      },
    },
  };
  </script>
  
  <style></style>