<template>
    <div style="height: 100%">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Id Riferimento:</label>
                            <span class="sa-data">{{ jsonData.id }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Modulo:</label>
                            <span class="sa-data">{{ jsonData.modulo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Documento:</label>
                            <span class="sa-data">{{ jsonData.idDocumento }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Esito Invocazione:</label>
                            <span class="sa-data">{{ jsonData.esitoInvocazione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Tipo Documento:</label>
                            <span class="sa-data">{{ jsonData.tipoDocumento }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Protocollo:</label>
                            <span class="sa-data">{{ jsonData.idProtocollo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Tipo Protocollo:</label>
                            <span class="sa-data">{{ jsonData.tipoProtocollo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Numero Protocollo:</label>
                            <span class="sa-data">{{ jsonData.numeroProtocollo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Anno Protocollo:</label>
                            <span class="sa-data">{{ jsonData.annoProtocollo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Data Protocollo:</label>
                            <span class="sa-data">{{ jsonData.dataProtocollo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Allegato:</label>
                            <pdf-viewer></pdf-viewer>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Allegato:</label>
                            <a ref="{{ jsonData.urlDocumentoTimbrato }}" class="sa-data">Allegato 2</a>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Allegato:</label>
                            <a ref="{{ jsonData.urlDocumentoSegnatura }}" class="sa-data">Allegato 3</a>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Motivo:</label>
                            <span class="sa-data">{{ jsonData.motivo }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Log" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-data">{{ jsonData.log }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Body" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <pre>{{ prettyFormat }}</pre>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <user-information :data="jsonData"></user-information>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
import UserInformation from "../../utenti/components/UserInformation.vue";
import PdfViewer from '../../configurazione/components/PdfViewer.vue';
export default {
    components: { UserInformation, PdfViewer },
    data() {
        
        return {
            pathResource: "/notificationgedocresponse",
            id: null,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        prettyFormat: function () {
            let me = this;
            me.jsonerror = "";
            let jsonValue = "";
            try {
                jsonValue = JSON.parse(me.jsonData.body);
            } catch (e) {
                console.log(e.message);
                me.jsonerror = JSON.stringify(e.message);
                var textarea = document.getElementById("jsonText");
                if (me.jsonerror.indexOf("position") > -1) {
                    // highlight error position
                    var positionStr = me.jsonerror.lastIndexOf("position") + 8;
                    var posi = parseInt(me.jsonerror.substr(positionStr, me.jsonerror.lastIndexOf('"')));
                    if (posi >= 0) {
                        textarea.setSelectionRange(posi, posi + 1);
                    }
                }
                console.log(me.jsonerror);
                return "";
            }
            return JSON.stringify(jsonValue, null, 2);
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>

<style></style>
