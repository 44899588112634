<template>
  <sa-page-layout @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <!-- <template slot="toolbar">
      <div style="float: right">
        <print-component :reportName="reportName" :data="items"></print-component>
      </div>
    </template> -->
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-select id="inline-form-custom-select-pref" v-model="filtroTotali.tipoFiltro" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoFiltroOption" :value="null"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroTotali.dataDal" type="date" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtroTotali.dataAl" type="date" placeholder="Data Al"></date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ getLabelTraduora("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ getLabelTraduora("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>

    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" fixed stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(dettaglio)="{ item }">
            <router-link class="sa-edit-link" :to="'/bancaematicarichiesterepartitotali?' + filtroTotali.tipoFiltro.toLowerCase() + '=' + item.dettaglio + '&dataDal=' + filtroTotali.dataDal + '&dataAl=' + filtroTotali.dataAl">{{ item.dettaglio }}</router-link>
          </template>
        </b-table>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout, DatePicker },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      pathResource: "/bancaematicatotali",
      showModalLoading: false,
      perPage: 10,
      currentPage: 1,
      filter: null,
      id: "-1",
      filtroTotali: { tipoFiltro: null, dataDal: null, dataAl: null },
      filterOn: [],
      fields: [
        {
          label: "Dettaglio",
          key: "dettaglio",
          sortable: true,
        },
        {
          label: "Totale",
          key: "totale",
          thStyle: "width: 10rem",
          sortable: true,
        },
      ],
      tipoFiltroOption: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "MEDICI", text: "MEDICI" },
        { value: "REPARTI", text: "REPARTI" },
      ],
      items: [],
      data: { stato: null, note: "", idBancaEmatica: "", destinatario: null },
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    // console.log(sessionStorage["filtro"]);
    if (sessionStorage["filtroTotali"] !== undefined && sessionStorage["filtroTotali"] !== null && sessionStorage["filtroTotali"] !== "undefined") {
      me.filtroTotali = JSON.parse(sessionStorage["filtroTotali"]);
      if (me.filtroTotali.dataDal !== null && me.filtroTotali.dataAl !== null && me.filtroTotali.tipofiltroTotali !== null) {
        me.loadData();
      }
    }
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?page=1&forPage=50";
      me.isBusy = true;
      axios
        .get(link, { params: me.filtroTotali })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
        })
        .catch((error) => {
          console.log(error);
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              console.log(value);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtroTotali"] = JSON.stringify(me.filtroTotali);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtroTotali = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtroTotali"] = JSON.stringify(me.filtro.codiceSacca);
      me.items = [];
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
