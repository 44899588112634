<template>
  <div>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Codice Documento:</label>
          <b-form-input v-model="jsonData.codiceTipoDocumento"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Descrizione:</label>
          <b-form-input v-model="jsonData.descrizione"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">MasterReport:</label>
          <b-form-input v-model="jsonData.master" disabled></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">SubReport:</label>
          <b-form-input v-model="jsonData.subreport" disabled></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="id !== '-1'" class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <print-report-import-file-component :jsonData="jsonDataReport" @update="onUpdateReport"></print-report-import-file-component>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import PrintReportImportFileComponent from "./PrintReportImportFileComponent.vue";
export default {
  components: { PrintReportImportFileComponent },
  data() {
    return {
      id: "-1",
      pathResource: "/printreporttipi",
      jsonData: { master: "MasterReport", codiceTipoDocumento: "", descrizione: "", subreport: "" },
      jsonDataReport: { name: "", base64: "", idReportTipo: "" },
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onUpdateReport(data) {
      let me = this;
      me.jsonDataReport = data;
      if (me.jsonDataReport.base64 !== null && me.jsonDataReport.base64 !== "") {
        let name = me.jsonDataReport.name;
        me.jsonData.subreport = name.replace(".jasper", "");
        me.jsonDataReport.idReportTipo = me.jsonData.id;
        me.updateReport();
      }
    },
    updateReport() {
      let me = this;
      console.log("upload");
      let link = process.env.VUE_APP_PATH_API + "/printreporttipi/upload";
      axios
        .post(link, me.jsonDataReport)
        .then((response) => {
          console.log(response);
          me.updateTipoReport();
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    updateTipoReport() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      link = link + "/" + me.jsonData.id;
      axios
        .put(link, JSON.stringify(me.jsonData))
        .then((response) => {
          this.$emit("update", response.data.data);
          this.$bvModal
            .msgBoxOk(response.data.messaggio, {
              title: "",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          this.$emit("afterLoadData");
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              console.log(value);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadData() {
      let me = this;
      me.id = this.$route.params.id;
      this.$emit("beforeLoadData");
      if (me.id !== "-1") {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          this.$emit("afterLoadData");
        });
      } else {
        this.$emit("afterLoadData");
      }
    },
  },
};
</script>

<style></style>
