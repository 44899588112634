<template>
  <sa-page-layout :titolo="titolo" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>Nuovo</b-button>
      <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
    </template>
    <template slot="table-filter"> </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table
          sticky-header
          ref="table"
          id="tblTask"
          :filter="filter"
          responsive
          stacked="lg"
          striped
          hover
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template #cell(nome)="{ item }">
            <router-link class="sa-edit-link" :to="'/taskmanager/view/' + item.id">{{ item.nome }}</router-link>
            <span class="sa-prossima-esecuzione">Pros. Exec. {{ formatDate(item.dataOraProssimaEsecuzione) }}</span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" class="mr-1" variant="secondary" @click="onTaskStart(row.item, row.index, $event.target)">
              <font-awesome-icon size="xs" icon="play" tabindex="-1" />
            </b-button>
            <b-button size="sm" class="mr-1" variant="secondary" @click="onTaskStop(row.item, row.index, $event.target)">
              <font-awesome-icon size="xs" icon="stop" tabindex="-1" />
            </b-button>
            <b-button size="sm" class="mr-1" variant="secondary" @click="onTaskEsegui(row.item, row.index, $event.target)">
              <font-awesome-icon size="xs" icon="cogs" tabindex="-1" />
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      titolo: "Lista Task",
      showModalLoading: false,
      currentPage: 1,
      perPage: 30,
      filter: "",
      isBusy: false,
      pagelink: "/taskmanager",
      pathResource: "/gesantask",
      fields: [
        {
          label: "Nome",
          key: "nome",
          sortable: true,
        },
        {
          label: "Stato",
          key: "stato",
          sortable: true,
          thStyle: "width: 8rem",
        },
        {
          label: "Tipo Avvio",
          key: "tipoAvvio",
          sortable: true,
          thStyle: "width: 8rem",
        },
        {
          label: "Ultimo Avvio",
          key: "dataOraUltimoStart",
          sortable: true,
          thStyle: "width: 9rem",
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
        {
          label: "Ultimo Stop",
          key: "dataOraUltimoStop",
          sortable: true,
          thStyle: "width: 9rem",
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
          },
        },
        { key: "actions", label: "", thStyle: "width: 9rem" },
      ],
      items: [],
      data: [],
      filtro: {},
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.loadData();
  },
  update: function() {
    console.log("Update");
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;

      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.showModalLoading = false;
        })
        .catch(function(error) {
          me.showModalLoading = false;
          me.$bvToast.toast(error.response.data.messaggio, {
            title: "Sorveglianza Covid Admin",
            variant: "danger",
            solid: true,
          });
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.pagelink + "/edit/-1").catch((err) => {
        err;
      });
    },
    onSubmit: function() {
      let me = this;
      me.loadData();
      return false;
    },
    onTaskStart: function(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/start/" + item.id;
      axios
        .get(link)
        .then(() => {
          //me.$refs["sa-modal-loading"].close();
          me.loadData();
        })
        .catch(function(error) {
          me.showModalLoading = false;
          me.$bvToast.toast(error.response.data.messaggio, {
            title: "Sorveglianza Covid Admin",
            variant: "danger",
            solid: true,
          });
        });
    },
    onTaskStop: function(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/stop/" + item.id;
      axios
        .get(link)
        .then(() => {
          me.showModalLoading = false;
          me.loadData();
        })
        .catch(function(error) {
          me.showModalLoading = false;
          me.$bvToast.toast(error.response.data.messaggio, {
            title: "Sorveglianza Covid Admin",
            variant: "danger",
            solid: true,
          });
        });
    },
    onTaskEsegui: function(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/esegui/" + item.id;
      axios
        .get(link)
        .then(() => {
          me.showModalLoading = false;
          me.loadData();
        })
        .catch(function(error) {
          me.showModalLoading = false;
          me.$bvToast.toast(error.response.data.messaggio, {
            title: "Sorveglianza Covid Admin",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>
<style scoped>
.sa-prossima-esecuzione {
  display: block;
  font-size: 0.8rem;
}
</style>
