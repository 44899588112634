<template>
  <sa-page-layout :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPre-TransfusionTubCode") }}</span>
              <b-form-input v-model="data.codiceProvettaPretrasfusionale" :placeholder="getLabelTraduora('bancaEmatica.lblPre-TransfusionTubCode')"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblRequestPacksNumber") }}</span>
              <b-form-input v-model="data.numeroSaccheRichieste" :placeholder="getLabelTraduora('bancaEmatica.lblRequestPacksNumber')"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblUrgencyDegree") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.gradoUrgenza" class="mb-2 mr-sm-2 mb-sm-0" :options="gradiUrgenzeOption" :value="null"></b-form-select>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblDiagnosisCode") }}</span>
              <b-form-input v-model="data.codiceDiagnosi" :placeholder="getLabelTraduora('bancaEmatica.lblDiagnosisCode')"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPackType") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.tipologiaSacca" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaSaccaOption" :value="null" @input="onTipologiaSaccaInput()"></b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblBloodGroup") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null" :disabled="disabilitato"></b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblRhFactor") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null" :disabled="disabilitato"></b-form-select>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPre-TransfusionTub") }}</span>
              <b-form-input v-model="data.provettaPretrasfusionale" :placeholder="getLabelTraduora('bancaEmatica.lblPre-TransfusionTub')"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblDepartmentCode") }}</span>
              <b-form-input v-model="data.codiceReparto" :placeholder="getLabelTraduora('bancaEmatica.lblDepartmentCode')"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblDepartment") }}</span>
              <b-form-input v-model="data.reparto" :placeholder="getLabelTraduora('bancaEmatica.lblDepartment')"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblOperatorRegistrationNumber") }}</span>
              <b-input-group class="mb-3">
                <b-form-input v-model="data.matricolaOperatore" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblOperatorRegistrationNumber')"></b-form-input>
                <b-button ref="refBtnOperatore" id="btnMatricolaOperatore" size="sm" variant="" v-b-modal.ricercaMatricolaOperatore @click="onClickMatricola('')">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblOperatorName") }}</span>
              <b-form-input v-model="data.nomeOperatore" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblOperatorName')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblOperatorSurname") }}</span>
              <b-form-input v-model="data.cognomeOperatore" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblOperatorSurname')"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblMedicRegistrationNumber") }}</span>
              <b-input-group class="mb-3">
                <b-form-input v-model="data.matricolaMedico" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblMedicRegistrationNumber')"></b-form-input>
                <b-button ref="refBtnMedico" id="btnMatricolaMedico" size="sm" variant="" v-b-modal.ricercaMatricolaMedico @click="onClickMatricola('MEDICO')">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblMedicName") }}</span>
              <b-form-input v-model="data.nomeMedico" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblMedicName')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblMedicSurname") }}</span>
              <b-form-input v-model="data.cognomeMedico" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblMedicSurname')"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblRequestReason") }}</span>
              <b-form-input v-model="data.motivoRichiesta" :placeholder="getLabelTraduora('bancaEmatica.lblRequestReason')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblAPTT") }}</span>
              <b-form-input v-model="data.aptt" :placeholder="getLabelTraduora('bancaEmatica.lblAPTT')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblHB") }}</span>
              <b-form-input v-model="data.hb" :placeholder="getLabelTraduora('bancaEmatica.lblHB')"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblINR") }}</span>
              <b-form-input v-model="data.inr" :placeholder="getLabelTraduora('bancaEmatica.lblINR')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPIT") }}</span>
              <b-form-input v-model="data.plt" :placeholder="getLabelTraduora('bancaEmatica.lblPIT')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPT") }}</span>
              <b-form-input v-model="data.pt" :placeholder="getLabelTraduora('bancaEmatica.lblPIT')"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblTypeOfBlood") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.tipologia" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaOption" :value="null"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblStructureCode") }}</span>
              <b-form-input v-model="data.codiceStruttura" :placeholder="getLabelTraduora('bancaEmatica.lblStructureCode')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("sequenziamento.lblstatoAttuale") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblRequestDate") }}</span>
              <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.dataRichiesta" type="datetime" :placeholder="getLabelTraduora('bancaEmatica.lblRequestDate')"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblExpirationDate") }}</span>
              <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.dataScadenza" type="datetime" :placeholder="getLabelTraduora('bancaEmatica.lblExpirationDate')"></date-picker>
            </b-col>
          </b-row>
        </div>

        <div>
          <h5>{{ getLabelTraduora("teleconsulto.lblPatientData") }}</h5>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("ambulatori.lblName") }}</span>
              <b-form-input v-model="data.nomePaziente"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("ambulatori.lblSurname") }}</span>
              <b-form-input v-model="data.cognomePaziente"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("patients.lblBirthDate") }}</span>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataNascitaPaziente" type="datetime"></date-picker>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("patients.lblProvince") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.descrizioneProvincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceNascitaPazienteOption" :value="null" @change="loadComuni(data.descrizioneProvincia)"></b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("patients.lblCity") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.luogoNascitaPaziente" class="mb-2 mr-sm-2 mb-sm-0" :options="luogoNascitaPazienteOption" :value="null"></b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("patients.lblGender") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.sessoPaziente" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null"></b-form-select>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblMedicalRecords") }}</span>
              <b-form-input v-model="data.cartellaClinica"></b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblRecoveryDate") }}</span>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataRicovero" type="datetime"></date-picker>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPreviousChildBirthAbortion") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.partiAbortiPregressi" class="mb-2 mr-sm-2 mb-sm-0" :options="selezioneOption" :value="null"></b-form-select>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblLastChildBirthAbortion") }}</span>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataUltimoAbortoParto" type="datetime"></date-picker>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPreviousTransfusion") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.trasfusioniPregresse" class="mb-2 mr-sm-2 mb-sm-0" :options="selezioneOption" :value="null"></b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblLastTransfusionDate") }}</span>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataUltimaTrasfusione" type="datetime"></date-picker>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblTransfusionReactions") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.reazioniTrasfusionali" class="mb-2 mr-sm-2 mb-sm-0" :options="selezioneOption" :value="null"></b-form-select>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblLastReactionDate") }}</span>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataUltimaReazioneTrasfusionale" type="datetime" placeholder="Data Ultima Reazione Trasfusionale"></date-picker>
            </b-col>
          </b-row>
        </div>
        <b-modal id="ricercaMatricolaMedico" title="Ricerca Matricola Medico" @hidden="onCloseModalMatricola()" size="xl" @ok="onOkMatricolaMedico">
          <b-row>
            <b-col cols="6">
              <span class="sa-form-label">{{ getLabelTraduora("anagrafica.lblFirstName") }}</span>
              <b-form-input v-model="filtroModal.nome" :placeholder="getLabelTraduora('anagrafica.lblFirstName')" @input="filtroMedico(filtroModal.nome)"></b-form-input
            ></b-col>
            <b-col cols="6">
              <span class="sa-form-label">{{ getLabelTraduora("anagrafica.lblLastName") }}</span>
              <b-form-input v-model="filtroModal.cognome" :placeholder="getLabelTraduora('anagrafica.lblLastName')" @input="filtroMedico(filtroModal.cognome)"></b-form-input
            ></b-col>
          </b-row>
          <br />
          <b-table id="tabellaRichieste" :items="tabellaMatricole" :per-page="perPage" :current-page="currentPage" :fields="matricoleFields" selectable select-mode="single" @row-selected="onRowSelected">
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
          <template>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
              <b-col cols="6">
                <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-modal>
        <b-modal id="ricercaMatricolaOperatore" title="Ricerca Matricola Operatore" @hidden="onCloseModalMatricola()" size="xl" @ok="onOkMatricola">
          <!-- <b-button size="sm" variant="" @click="onClickMatricola"></b-button> -->
          <!-- <b-input-group class="mb-3">
                    <b-form-input v-model="numeroRichiesta" placeholder="Richieste"></b-form-input>
                   
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group> -->
          <b-row>
            <b-col cols="4">
              <span class="sa-form-label">{{ getLabelTraduora("anagrafica.lblFirstName") }}</span>
              <b-form-input v-model="filtroModal.nome" :placeholder="getLabelTraduora('anagrafica.lblFirstName')" @input="filtroMedico(filtroModal.nome)"></b-form-input
            ></b-col>
            <b-col cols="4">
              <span class="sa-form-label">{{ getLabelTraduora("anagrafica.lblLastName") }}</span>
              <b-form-input v-model="filtroModal.cognome" :placeholder="getLabelTraduora('anagrafica.lblLastName')" @input="filtroMedico(filtroModal.cognome)"></b-form-input
            ></b-col>
            <b-col cols="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblTypeOfBlood") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="filtroModal.tipo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoOption" :value="null" value-field="descrizione" text-field="descrizione" @input="onClickMatricola(filtroModal.tipo)">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <br />
          <b-table id="tabellaMatricole" :items="tabellaMatricole" :per-page="perPage" :current-page="currentPage" :fields="matricoleFields" selectable select-mode="single" @row-selected="onRowSelected">
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
          <template>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
              <b-col cols="6">
                <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
              </b-col>
              <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </b-modal>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker, SaPageLayout },
  data() {
    return {
      pathResource: "/bancaematicatrasfusionirichiestereparti",
      pathRESTRequest: "/bancaematica",
      linkstato: "/bancaematicatrasfusionirichiesterepartistatitipi",
      linkgrupposanguigno: "/bancheematichegruppisanguigni",
      linktipologie: "/bancheematichetipiemoderivatitipologie",
      linktipologiesacche: "/bancaematicatipologiesacche",
      linkfattorerh: "/bancaematicafattorirh",
      pathResourceAggiornaStato: "/aggiornaStato",
      linkedit: null,
      linkProvince: "/province",
      linkComune: "/comuni/provincia",
      linkGradiUrgenze: "/bancaematicagradiurgenze",
      linkback: null,
      showModalLoading: false,
      disabilitato: false,
      filtroModal: { nome: "", cognome: "", tipo: null },
      id: "-1",
      profili: [],
      queryJsonData: {},
      statoOption: [{ value: null, text: "--Seleziona Valore--" }],
      gruppoSanguignoOption: [{ value: null, text: "--Seleziona Valore--" }],
      tipologiaSaccaOption: [{ value: null, text: "--Seleziona Valore--" }],
      fattoreRhOption: [{ value: null, text: "--Seleziona Valore--" }],
      tabellaRichieste: [],
      rigaSelezionata: null,
      currentPage: 1,
      perPage: 10,
      tipologiaRichiesta: null,
      pathRestRichiesteScorte: "/bancheematichetrasfusionirichiestescorte",
      pathRestOperatori: "/bancaematicaoperatoretipi",
      pathRESTMatricoleOperatori: "/bancaematicaoperatori",
      data: {
        statoAttuale: null,
        tipologiaSacca: null,
        gruppoSanguigno: null,
        fattoreRh: null,
        luogoNascitaPaziente: null,
        sessoPaziente: null,
        tipologia: null,
        trasfusioniPregresse: null,
        reazioniTrasfusionali: null,
        partiAbortiPregressi: null,
        gradoUrgenza: null,
      },

      tabellaMatricole: [],
      matricoleFields: [
        {
          label: "",
          key: "selected",
          sortable: true,
          thStyle: "width: 2rem",
        },
        {
          label: "Numero Matricola",
          key: "matricola",
          sortable: true,
          thStyle: "width: 7rem",
        },

        {
          label: "Cognome",
          key: "cognome",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: "Nome",
          key: "nome",
          sortable: true,
          thStyle: "width: 7rem",
        },
      ],
      provinceNascitaPazienteOption: [{ value: null, text: "--Seleziona Provincia--" }],
      luogoNascitaPazienteOption: [{ value: null, text: "--Seleziona Comune--" }],
      tipologiaOption: [{ value: null, text: "--Seleziona Valore--" }],
      sessoOption: [
        { value: null, text: "-Sel. Sesso-" },
        { value: "M", text: "Maschio" },
        { value: "F", text: "Femmina" },
      ],
      tipoOption: [],
      selezioneOption: [
        { value: null, text: "-Seleziona Risposta-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      gradiUrgenzeOption: [{ value: null, text: "-Seleziona Livello di Urgenza-" }],
    };
  },
  computed: {
    rows() {
      return this.tabellaMatricole.length;
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.queryJsonData = this.$route.query.defaultData;
    if (me.id === "-1") {
      me.linkback = "/bancaematicarichiestereparto";
    } else {
      me.linkback = "/bancaematicarichiestereparto/view/" + me.id;
    }
    me.loadDefaultData();
    if (this.$route.query.defaultData) {
      me.setDataFromQueryJson();
    }
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadData();
      me.loadProvince();
      me.loadFattoreRh();
      me.loadGruppoSanguigno();
      me.loadTipologiaSacca();
      me.loadTipologia();
      me.loadGradiUrgenze();
      me.loadStato();
      me.loadTipoOperatore();
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.data = response.data.data;
          me.loadComuni(me.data.descrizioneProvincia);
          me.showModalLoading = false;
        });
      }
    },
    loadTipoOperatore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRestOperatori + "?forPage=100000&page=1";
      axios.get(link).then((response) => {
        me.tipoOption = response.data.data.list;
        me.filtroModal.tipo = me.tipoOption[1].descrizione;
      });
    },
    onOkMatricola() {
      let me = this;
      me.data.matricolaOperatore = me.rigaSelezionata.matricola;
      me.data.nomeOperatore = me.rigaSelezionata.nome;
      me.data.cognomeOperatore = me.rigaSelezionata.cognome;
    },
    onOkMatricolaMedico() {
      let me = this;
      me.data.matricolaMedico = me.rigaSelezionata.matricola;
      me.data.nomeMedico = me.rigaSelezionata.nome;
      me.data.cognomeMedico = me.rigaSelezionata.cognome;
    },
    onCloseModalMatricola() {
      let me = this;
      me.tabellaMatricole = [];
      me.filtroModal.nome = "";
      me.filtroModal.cognome = "";
      me.filtroModal.tipo = null;
    },
    loadStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkstato;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.statoOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    onClickMatricola(tipo) {
      let me = this;
      //tipo = !tipo ? "" : tipo;
      me.showModalLoading = true;
      if (tipo !== "" && tipo != null) {
        me.filtroModal.tipo = tipo;
      } else {
        me.filtroModal.tipo = me.tipoOption[1].descrizione;
      }
      let link = process.env.VUE_APP_PATH_API + me.pathRESTMatricoleOperatori + "?forPage=10&page=1&tipo=" + me.filtroModal.tipo + "&" + "nome=" + me.filtroModal.nome + "&cognome=" + me.filtroModal.cognome;
      axios
        .get(link)
        .then((response) => {
          console.log(response.data.data);
          me.tabellaMatricole = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          me.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
      me.showModalLoading = false;
    },
    filtroMedico(value) {
      let me = this;
      if (value) {
        me.tabellaMatricole = me.tabellaMatricole.filter((element) => {
          return element.nome.toUpperCase().includes(value.toUpperCase()) || element.cognome.toUpperCase().includes(value.toUpperCase());
        });
      } else {
        me.onClickMatricola("MEDICO");
      }
    },
    loadProvince() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinceNascitaPazienteOption.push({
            value: element.sigla,
            text: element.provincia,
          });
        });
      });
    },
    loadComuni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + me.data.descrizioneProvincia;
      axios.get(link).then((response) => {
        me.luogoNascitaPazienteOption = [];
        response.data.data.forEach((element) => {
          me.luogoNascitaPazienteOption.push({
            value: element.codiceIstat,
            text: element.comune,
          });
        });
      });
    },
    loadGruppoSanguigno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    loadGradiUrgenze() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkGradiUrgenze;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gradiUrgenzeOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    loadFattoreRh() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.fattoreRhOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    loadTipologia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linktipologie;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipologiaOption.push({
            value: element.tipologia,
            text: element.tipologia,
          });
        });
      });
    },
    loadTipologiaSacca() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linktipologiesacche;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipologiaSaccaOption.push({
            value: element.tipologiaSacca,
            text: element.tipologiaSacca,
          });
        });
      });
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else {
        return "-";
      }
    },
    onShow() {
      let me = this;
      me.tabellaRichieste = [];
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rigaSelezionata = items[0];
      }
    },
    onTipologiaSaccaInput() {
      let me = this;
      me.disabilitato = false;
      if (me.data.tipologiaSacca === "Piastrine") {
        me.disabilitato = true;
      }
    },
    onOk() {
      let me = this;
      me.data.codiceProvettaPretrasfusionale = me.rigaSelezionata.codiceProvettaPretrasfusionale;
      me.data.hb = me.rigaSelezionata.hb;
      me.data.inr = me.rigaSelezionata.inr;
      me.data.pt = me.rigaSelezionata.pt;
      me.data.plt = me.rigaSelezionata.plt;
      me.data.aptt = me.rigaSelezionata.aptt;
      me.data.gruppoSanguigno = me.rigaSelezionata.gruppoSanguigno;
      me.data.codiceSacca = me.rigaSelezionata.codiceSacca;
      me.data.fattoreRh = me.rigaSelezionata.fattoreRh;
    },
    setDataFromQueryJson() {
      let me = this;
      me.queryJsonData = JSON.parse(me.queryJsonData);
      console.log(me.queryJsonData);
      me.data.codiceReparto = me.queryJsonData.codreparto;
      me.data.reparto = me.queryJsonData.reparto;
      me.data.matricolaMedico = me.queryJsonData.matricolamedico;
      me.data.matricolaOperatore = me.queryJsonData.matricolaoper;
      me.data.nomePaziente = me.queryJsonData.nome;
      me.data.cognomePaziente = me.queryJsonData.cognome;
      me.data.dataNascitaPaziente = moment(me.queryJsonData.datanascita, "YYYY-MM-DD")
        .toDate()
        .getTime();
      me.data.descrizioneProvincia = me.queryJsonData.provincianascita;
      // console.log(me.data.descrizioneProvincia);
      me.loadComuni(me.data.descrizioneProvincia);
      me.data.luogoNascitaPaziente = me.queryJsonData.comunenascita;
      // console.log(me.data.luogoNascitaPaziente);
      if (me.queryJsonData.sesso !== "M" || me.queryJsonData.sesso !== "F") {
        me.data.sessoPaziente = null;
      }
      me.data.sessoPaziente = me.queryJsonData.sesso;

      me.data.cartellaClinica = me.queryJsonData.cartellaclinica;
      me.data.dataRicovero = moment(me.queryJsonData.dataricovero, "YYYY-MM-DD")
        .toDate()
        .getTime();
      if (me.queryJsonData.partiabortipreg !== "SI" || me.queryJsonData.partiabortipreg !== "NO") {
        me.data.partiAbortiPregressi = null;
      }
      me.data.partiAbortiPregressi = me.queryJsonData.partiabortipreg;

      me.data.dataUltimoAbortoParto = moment(me.queryJsonData.dataultimoabortoparto, "YYYY-MM-DD")
        .toDate()
        .getTime();
      me.data.nomeMedico = me.queryJsonData.nomeMedico;
      me.data.cognomeMedico = me.queryJsonData.cognomeMedico;
    },
    onClick() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRestRichiesteScorte + "/";
      axios
        .get(link, { params: { tipologia: me.tipologiaRichiesta } })
        .then((response) => {
          me.tabellaRichieste = response.data.data;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          me.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
  },
};
</script>
