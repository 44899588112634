import Protocollo from "../";

const routes = [
    
    { name: "ProtocolloUtentiList", path: "/protocolloutenti", component: Protocollo.ProtocolloUtentiList, meta: { title: "Protocollo Utenti" } },
    { name: "ProtocolloUtentiView", path: "/protocolloutenti/view/:id", component: Protocollo.ProtocolloUtentiView, meta: { title: "Protocollo Utenti" } },
    { name: "ProtocolloUtentiEdit", path: "/protocolloutenti/edit/:id", component: Protocollo.ProtocolloUtentiEdit, meta: { title: "Protocollo Utenti" } },
    { name: "ProtocolloModuliList", path: "/protocollomoduli", component: Protocollo.ProtocolloModuliList, meta: { title: "Protocollo Moduli" } },
];

export default routes;
