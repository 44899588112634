<template>
  <div>
    <b-card class="sa-card" header="Gruppo Malattie Infettive" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-checkbox v-model="isMalattieInfettiveSegnalazione" name="check-button" switch></b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Modulo:</label>
          <b-form-select v-model="jsonData.idModule" :options="moduleOptions" value-field="value" text-field="text" disabled></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Protocollo:</label>
          <b-form-select v-model="jsonData.tipo" :options="tipoOptions" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Gruppo:</label>
          <b-form-select v-if="isMalattieInfettiveSegnalazione" v-model="jsonData.gruppo" :options="malattieInfettiveUosdOptions" value-field="value" text-field="text"></b-form-select>
          <b-form-input v-else v-model="jsonData.gruppo"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="isEsterno" class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Persona:</label>
          <b-form-select v-model="jsonData.tipoPersona" :options="tipoPersonaOptions" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nominativo:</label>
          <b-form-input v-model="jsonData.destinatario"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">E-Mail Destinatario:</label>
          <b-form-input v-model="jsonData.destinatarioMail"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ufficio:</label>
          <b-form-select v-model="jsonData.codiceUfficio" :options="ufficiOptions" value-field="id" text-field="descrizione" @change="onSelectUfficio"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Utente:</label>
          <b-form-select v-model="jsonData.username" :options="utentiOptions" value-field="value" text-field="text" @change="onSelectUtente"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Oggetto:</label>
          <b-form-input v-model="jsonData.oggetto"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  data() {
    return {
      id: "-1",
      pathResource: "/protocolmoduledestinations",
      pathResourceUffici: "/protocolgedoc/uffici",
      pathResourceUtenti: "/protocolgedoc/utentibyufficio",
      pathResourceModule: "/protocolmodules",
      pathResourceMalattieInfettiveUosd: "/malattieinfettiveuosd",
      isMalattieInfettiveSegnalazione: false,
      isEsterno: false,
      protocolloModulo: {},
      tipoOptions: [
        { text: "-Selezionare il Tipo-", value: "" },
        { text: "ENTRATA", value: "E" },
        { text: "INTERNO", value: "I" },
        { text: "USCITA", value: "U" },
        { text: "DESTINATARIO ESTERNO", value: "DESTINATARIESTERNI" },
      ],
      tipoPersonaOptions: [
        { text: "-Selezionare il Tipo-", value: "" },
        { text: "FISICA", value: "F" },
        { text: "GIURIDICA", value: "G" },
      ],
      malattieInfettiveUosdOptions: [],
      ufficiOptions: [],
      listaUffici: [],
      utentiOptions: [{ text: "-Selezionare Utente-", value: "" }],
      listaUtenti: [],
      moduleOptions: [],
      jsonData: {
        tipo: "",
        codiceUfficio: "",
        oggetto: "",
        username: "",
        descrizioneUfficio: "",
        idModule: "",
        tipoPersona: "",
        destinatario: "",
        destinatarioMail: "",
        gruppo: "",
      },
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      let sessionStorage = window.sessionStorage;
      if (sessionStorage["protocolloModulo"]) {
        me.protocolloModulo = JSON.parse(sessionStorage["protocolloModulo"]);
        me.jsonData.idModule = me.protocolloModulo.id;
      }
    }
    me.loadModule();
    me.loadMalattieInfettiveUosd();
    me.loadUffici();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.tipo": function (value) {
      let me = this;
      if (value === "DESTINATARIESTERNI") {
        me.isEsterno = true;
      } else {
        me.isEsterno = false;
      }
    },
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadData() {
      let me = this;
      this.$emit("beforeLoadData");
      if (me.id !== "-1") {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          if (me.jsonData.tipo !== "DESTINATARIESTERNI") {
            me.loadListaUtenti();
            me.isEsterno = false;
          }
          this.$emit("afterLoadData");
        });
      } else {
        this.$emit("afterLoadData");
      }
    },
    loadMalattieInfettiveUosd() {
      let me = this;
      me.malattieInfettiveUosdOptions = [];
      let filtro = { page: 1, forPage: 100 };
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattieInfettiveUosd;
      //   axios.get(link).then((response) => {
      //     response.data.data.list.forEach((element) => {
      //       me.malattieInfettiveUosdOptions.push({ text: element.descrizione, value: element.descrizione });
      //     });
      //     me.malattieInfettiveUosdOptions.unshift({ text: "-Seleziona Gruppo-", value: "" });
      //   });
      UtilityMixin.methods.restCallGet(link, filtro, me.loadMalattiInfettiveUosdResponse, me.loadMalattiInfettiveUosdResponseError);
    },

    loadMalattiInfettiveUosdResponse(response) {
      let me = this;
      response.data.data.list.forEach((element) => {
        me.malattieInfettiveUosdOptions.push({ text: element.descrizione, value: element.descrizione });
      });
      me.malattieInfettiveUosdOptions.unshift({ text: "-Seleziona Gruppo-", value: "" });
    },
    loadMalattiInfettiveUosdResponseError(error) {
      this.$bvModal
        .msgBoxOk(error.response.data.messaggio, {
          title: "ATTENZIONE",
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "sa-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadUffici() {
      let me = this;
      me.listaUffici = [];
      me.ufficiOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.pathResourceUffici;

      UtilityMixin.methods.restCallGet(link, null, me.loadUfficiResponse, me.loadUfficiResponseError);
    },
    loadUfficiResponse(response) {
      let me = this;
      me.listaUffici = response.data.data.lista_uffici;
      me.ufficiOptions = response.data.data.lista_uffici;
      me.ufficiOptions.unshift({ descrizione: "-Seleziona Ufficio-", id: "" });
    },
    loadUfficiResponseError(error) {
      this.$bvModal
        .msgBoxOk(error.response.data.messaggio, {
          title: "ATTENZIONE",
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "sa-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSelectUfficio() {
      let me = this;
      me.listaUffici.forEach((element) => {
        if (element.id === me.jsonData.codiceUfficio) {
          me.jsonData.descrizioneUfficio = element.descrizione;
        }
      });
      me.loadListaUtenti();
    },
    loadListaUtenti() {
      let me = this;
      me.listaUtenti = [];
      let filtro = { idUfficio: me.jsonData.codiceUfficio };
      me.utentiOptions = [{ text: "-Selezionare Utente-", value: "" }];
      let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti;

      UtilityMixin.methods.restCallGet(link, filtro, me.loadListaUtentiResponse, me.loadListaUtentiResponseError);
    },
    loadListaUtentiResponse(response) {
      let me = this;
      me.listaUtenti = response.data.data.lista_utenti;
      response.data.data.lista_utenti.forEach((element) => {
        me.utentiOptions.push({ text: element.username, value: element.username });
      });
    },
    loadListaUtentiResponseError(error) {
      this.$bvModal
        .msgBoxOk(error.response.data.messaggio, {
          title: "ATTENZIONE",
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "sa-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSelectUtente() {
      let me = this;
      me.listaUtenti.forEach((element) => {
        if (element.usename === me.jsonData.username) {
          me.jsonData.destinatario = element.cognome + " " + element.nome;
        }
      });
    },
    loadModule() {
      let me = this;
      me.moduleOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.pathResourceModule;
      UtilityMixin.methods.restCallGet(link, null, me.loadModuleResponse, me.loadModuleResponseError);
    },
    loadModuleResponse(response) {
      let me = this;
      me.listaUtenti = response.data.data.lista_utenti;
      response.data.data.list.forEach((element) => {
        me.moduleOptions.push({ text: element.modulo + " (" + element.documento + ")", value: element.id });
      });
      me.moduleOptions.unshift({ text: "-Seleziona Modulo-", value: "" });
    },
    loadModuleResponseError(error) {
      this.$bvModal
        .msgBoxOk(error.response.data.messaggio, {
          title: "ATTENZIONE",
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "sa-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
