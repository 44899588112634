<template>
  <div>
    <b-row>
      <b-col class="sa-padding-right text-right">
        <b-button variant="outline-info" @click="onTblCardiolineRefresh">
          <b-icon icon="arrow-clockwise" />
          Aggiorna
        </b-button>
      </b-col>
    </b-row>
    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
      <b-col lg="3">
        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
      </b-col>
      <b-col lg="9">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
      </b-col>
    </b-row>
    <b-table sticky-header ref="table" stacked="xl" striped hover :items="listaRichieste" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" no-header>
      <template v-slot:cell(actions)="row">
        <b-button v-if="row.item.base64 !== null" size="sm" variant="btn btn-outline-purple waves-effect waves-light width-sm no-text" style="margin-right: 5px" @click="onVisualizza(row.item)">
          <i class="bi bi-file-pdf"></i>
        </b-button>
      </template>
    </b-table>
    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
      <b-col lg="3">
        <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
      </b-col>
      <b-col lg="9">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
      </b-col>
    </b-row>
    <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
      <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
    </b-modal>
    <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="stampaReport"></pdf-print-view-embedded>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PdfPrintViewEmbedded from "../../../utility/components/PdfPrintViewEmbedded.vue";
export default {
  props: {
    idRisorsa: {
      type: String,
      require: true,
    },
    btnResendVisible: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    listaRichieste: {
      type: Array[Object],
      default: function () {
        return [];
      },
    },
  },
  components: { PdfPrintViewEmbedded },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  mounted() {
    let me = this;
    // console.log(this.listaRichieste);
    me.id = this.$route.params.id;
    // console.log(me.idRisorsa);
    me.loadData();
  },
  data() {
    return {
      rows: 0,
      pathResource: "/mirvisite",
      currentPage: 1,
      perPage: 10,
      id: "",
      filtro: {},
      stampaReport: null,
      // richiesteMirItems: [{ dataOra: 0, stato: "INVIO IN CORSO", risposta: "Antonio Mona è strunz." }],
      // richiesteMirItems: [],
      fields: [
        {
          label: "Data Ora",
          key: "statoAttualeDataOra",
          formatter: function (value) {
            return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
          },
          thStyle: "width: 10rem",
        },
        {
          label: "Postazione Richiesta",
          key: "postazioneRichiesta",
        },
        {
          label: "Stato",
          key: "statoAttuale",
          thStyle: "width: 11rem",
        },

        { class: "sa-table-column-action-single", key: "actions", label: "", thStyle: "width: 5rem" },
      ],
    };
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.forPage = me.perPage;
      me.filtro.page = me.currentPage;
      me.filtro.idVisita = me.id;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          // me.richiesteMirItems = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          this.$emit("update", response.data.data.list);
          response.data.data.list.forEach((element) => {
            this.$emit("updateBase64", element);
          });
          me.showModalLoading = false;
        })
        .catch((error) => {
          console.log(error);
          // me.showModalLoading = false;
          // this.$bvModal
          //   .msgBoxOk(error.response.data.messaggio, {
          //     title: "ATTENZIONE",
          //     size: "sm",
          //     okVariant: "outline-danger",
          //     headerClass: "sa-msg-header-danger",
          //     footerClass: "p-2 border-top-0",
          //     centered: true,
          //   })
          //   .then((value) => {
          //     console.log(value);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        });
    },
    onTblCardiolineRefresh() {
      let me = this;
      me.loadData();
    },
    onClickResend(item) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/send/" + item.id;
      // let cardiolineRequest = { examType: "ECG", unitId: 1, firstName: me.jsonData.nome, lastName: me.jsonData.cognome, patientId: me.jsonData.codiceFiscale, gender: me.jsonData.sesso, birthdate: moment(new Date(me.jsonData.dataNascita)).format("YYYYMMDD"), action: "", idRisorsa: me.jsonData.id };
      me.showModalLoading = true;
      axios
        .put(link) //cardiolineRequest
        .then(() => {
          me.showModalLoading = false;
        })
        .catch((error) => {
          console.log(error);
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              console.log(value);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onClickDelete(item) {
      console.log(item);
    },
    onClickViewPdf(item) {
      let me = this;
      // me.$refs.mdlGenerazioneReportInCorso.hide();
      me.$refs.mdlStampaReferto.show();
      me.stampaReport = "data:application/pdf;base64," + item.base64;
    },
    onClickOpenCardiolineApp(item) {
      let link = "http://192.168.16.201/EcgWebApp/Ecg/EditReport?id=" + item.examId;
      window.open(link);
    },
    onVisualizza(value) {
      let me = this;
      me.$refs.pdfPrintViewEmbedded.show();
      me.stampaReport = value.base64.includes("data:application/pdf;base64,") ? value.base64 : "data:application/pdf;base64," + value.base64;
    },
  },
};
</script>
<style scoped>
.btn-cardioline-outline:hover {
  color: #fff;
  background-color: #ee5b00;
  border-color: #ee5b00;
}

.btn-cardioline-outline {
  color: #ee5b00;
  background-color: transparent;
  border-color: #ee5b00;
}
.sa-icon-i-sm {
  margin-right: 10px;
  height: 21px;
  width: 21px;
  font-size: 1.4rem;
  font-size: 1.4rem;
  margin-top: -5px;
}
</style>
