<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
      <template slot="table-body">
        <gedoc-protocolli-list-component ref="GedocProtocolliListComponent" @afterLoadData="onAfterLoadData"></gedoc-protocolli-list-component>
      </template>
    </sa-page-layout>
  </template>
  
  <script>

import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import GedocProtocolliListComponent from "../components/GedocProtocolliListComponent.vue";
  
  export default {
    components: { SaPageLayout, GedocProtocolliListComponent },
    data() {
      return {
        btnNewVisible: true,
        pathResource: "",
        linkedit: "/gedocprotocollinotification",
        showModalLoading: false,
        listKey: 0,
      };
    },
    mounted() {
      let me = this;
      me.loadData();
    },
    methods: {
      loadData() {
        let me = this;
        me.showModalLoading = true;
        me.$refs.GedocProtocolliListComponent.loadData();
      },
      onRefresh() {
        let me = this;
        me.loadData();
      },
      onAfterLoadData() {
        let me = this;
        me.showModalLoading = false;
      },
    },
  };
  </script>
  
  <style></style>