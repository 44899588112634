<template>
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="table-body">
      <protocollo-utenti-view-component ref="ProtocolloUtentiViewComponent" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></protocollo-utenti-view-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ProtocolloUtentiViewComponent from "../components/ProtocolloUtentiViewComponent.vue";
export default {
  components: { SaPageLayout, ProtocolloUtentiViewComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: "/protocolloutenti",
      linkedit: "",
      id: "",
      jsonData: {},
      showModalLoading: false,
      pathResource: "/protocolmoduledestinations",
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/protocolloutenti/edit/" + me.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.ProtocolloUtentiViewComponent.loadData();
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
