<template>
  <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData" :isPrestazioni="true" :isImmagini="true" :isAllegati="false" :btnFirmaVisible="btnFirmaVisible" @updateFirma="onFirmaUpdate">
    <template slot="tab-content-event">
      <ecografia-addominale-view-component @update="onUpdateJsonData" :idEvento="id" :key="ecografiaaddominaleViewKey"></ecografia-addominale-view-component>
    </template>
  </eventi-view-component>
</template>

<script>
import EcografiaAddominaleViewComponent from "./EcografiaAddominaleViewComponent.vue";
// import axios from "axios";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  components: { EventiViewComponent, EcografiaAddominaleViewComponent },
  mixins: [UtilityMixin, EventiMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      titoloEvento: "Ecografia Addome Superiore",
      linkback: null,
      linkedit: null,
      jsonData: {},
      pathResourceFirma: "/gastroecografiaaddominale",
      pathResource: "/gastroecografiaaddominale",
      btnFirmaVisible: true,
      tabIndex: 0,
      id: null,
      pathRestValidation: "/validation",
      isThisFirmato: false,
      showModalLoading: false,
      linkPrintData: null,
      ecografiaaddominaleViewKey: 0,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;
    me.ecografiaaddominaleViewKey++;
  },

  methods: {
    updateStatoFirma(firmato) {
      console.log(firmato);
    },
    onFirmaUpdate() {
      let me = this;
      me.btnFirmaVisible = false;
      me.ecografiaaddominaleViewKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      if (me.jsonData.firma) {
        me.btnFirmaVisible = false;
      } else {
        me.btnFirmaVisible = true;
      }
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>

<style></style>
