import DataAnalysis from "../";

const routes = [
    { name: "Elenco Tabelle", path: "/dataanalysistables", component: DataAnalysis.DataAnalysisTablesListPage, meta: { title: "Elenco Accettazione" } },
    { name: "Data Analysis Table Edit", path: "/dataanalysistables/edit/:id", component: DataAnalysis.DataAnalysisTablesEditPage, meta: { title: "Digita Sign" } },
    { name: "Data Analysis Query List", path: "/dataanalysisquery", component: DataAnalysis.DataAnalysisQueryListPage, meta: { title: "Query Builder Edit" } },
    { name: "Data Analysis Query Edit", path: "/dataanalysisquery/edit/:id", component: DataAnalysis.DataAnalysisQueryEditPage, meta: { title: "Digita Sign" } },
    { name: "Data Analysis Dashboard List", path: "/dataanalysisdashboards", component: DataAnalysis.DataAnalysisDashboardListPage, meta: { title: "Dashboard" } },
    { name: "Data Analysis Dashboard View", path: "/dataanalysisdashboards/view/:id", component: DataAnalysis.DataAnalysisDashboardViewPage, meta: { title: "Dashboard" } },
];
export default routes;
