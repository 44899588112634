<template>
    <sa-page-layout ref="pgaView" :btnBackVisible="true" :btnEditVisible="true" :pathResource="pathResource" :data="jsonData" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
       
        <template slot="table-header">
            
        </template>
        <template slot="table-body">
            <b-row >
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{getLabelTraduora('bancaEmatica.lblOperatorTypeId')}}</strong> 
                        <br />
                        {{jsonData.id}}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{getLabelTraduora('bancaEmatica.lblOperatorTypeName')}}</strong>
                        <br>
                        {{jsonData.descrizione}}
                    </b-col>
                    
                </b-row>
        </template>
        <template slot="table-footer">
          
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins:[UtilityMixin],
    components: { SaPageLayout },
    computed: {
    },
    data() {
        return {
            showModalLoading: false,
            linkback: "/bancaematicaoperatoretipi",
            reportName: "ReportDatiRepartoBancaEmatica",
            linkedit: null,
            pathResource: "/bancaematicaoperatoretipi",
            
            jsonData: {  },
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/bancaematicaoperatoretipi/edit/" + me.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                //me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                });
            }
        }
    },
};
</script>
