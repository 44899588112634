<template>
    <b-card class="sa-card" header="Dati Task" header-tag="header" footer-tag="footer" title="">
        <b-row>
            <b-col xs="12" sm="4" md="3" lg="4" xl="4">
                <label class="sa-label-data">Stato:</label>
                <span class="sa-text-data">{{ jsonData.stato }}</span>
            </b-col>
            <b-col xs="12" sm="4" md="3" lg="4" xl="4">
                <label class="sa-label-data">Nome:</label>
                <b-form-input v-model="jsonData.nome" size="sm" placeholder="Nome"></b-form-input>
            </b-col>
            <b-col xs="12" sm="4" md="3" lg="4" xl="4">
                <label class="sa-label-data">Tipo Avvio:</label>
                <b-form-select v-model="jsonData.tipoAvvio" :options="options"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Descrizione:</label>
                <b-form-textarea id="textarea" v-model="jsonData.descrizione" placeholder="Descrizione del task..." rows="7"></b-form-textarea>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Nome Classe:</label>
                <b-form-input v-model="jsonData.classeJava" size="sm" placeholder="Nome"></b-form-input>
            </b-col>
            <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">Ultimo Avvio:</label>
                <span class="sa-text-data">{{ formatDate(jsonData.dataOraUltimoStart) }}</span>
            </b-col>
            <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">Ultimo Stop:</label>
                <span class="sa-text-data">{{ formatDate(jsonData.dataOraUltimoStop) }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Data Avvio:</label>
                <date-picker format="DD-MM-YYYY HH:mm:sss" value-type="timestamp" v-model="jsonData.avvioData" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Prossima Exec.:</label>
                <span class="sa-text-data">{{ formatDate(jsonData.dataOraProssimaEsecuzione) }}</span>
            </b-col>
            <b-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                <label class="sa-label-data">Rip. Mese:</label>
                <span class="sa-text-data">{{ formatDate(jsonData.dataOraProssimaEsecuzione) }}</span>
            </b-col>
            <b-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                <label class="sa-label-data">
                    Rip. Giorno (
                    <span class="sa-text-data">{{ jsonData.ripetizioneGiorno }}</span>
                    ):
                </label>
                <b-form-input v-model="jsonData.ripetizioneGiorno" type="range" min="0" max="30"></b-form-input>
            </b-col>
            <b-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                <label class="sa-label-data">
                    Rip. Ore (
                    <span class="sa-text-data">{{ jsonData.ripetizioneOre }}</span>
                    ):
                </label>
                <b-form-input v-model="jsonData.ripetizioneOre" type="range" min="0" max="24"></b-form-input>
            </b-col>
            <b-col cols="6" xs="6" sm="6" md="3" lg="3" xl="3">
                <label class="sa-label-data">
                    Rip. Minuti (
                    <span class="sa-text-data">{{ jsonData.ripetizioneMinuti }}</span>
                    ):
                </label>
                <b-form-input v-model="jsonData.ripetizioneMinuti" type="range" min="0" max="59"></b-form-input>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import axios from "axios";
export default {
    components: { DatePicker },
    data() {
        return {
            pathResource: "/gesantask",
            linkback: "/taskmanager",
            linkedit: null,
            showmolalloading: false,
            id: "-1",
            options: [
                { value: null, text: "Selezionare Tipo Avvio" },
                { value: "Automatico", text: "Automatico" },
                { value: "Manuale", text: "Manuale" },
            ],
            jsonData: {},
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                me.showmolalloading = true;
                me.linkback = "/taskmanager/view/" + me.id;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
    },
};
</script>

<style></style>
