<template>
  <b-modal ref="mdlSigendPdfViewer" size="xl" style="height: 100%" scrollable>
    <!-- <button @click="$refs.pdfEmbeddedComponent.print()">print Sisto</button> -->
    <vue-pdf-embed ref="pdfEmbeddedComponent" :source="pdf"></vue-pdf-embed>
    <template #modal-footer>
      <b-button size="sm" variant="danger" @click="onCloseClick()">Chiudi</b-button>
      <b-button size="sm" variant="outline-secondary" @click="onPrint()">
        <b-icon icon="printer"></b-icon>
        Stampa
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  components: { VuePdfEmbed },
  props: {
    pdf: String,
  },
  methods: {
    show() {
      // console.log("SHOW");
      this.$refs.mdlSigendPdfViewer.show();
    },
    onPrint() {
      this.$refs.pdfEmbeddedComponent.print();
    },
    onCloseClick() {
      this.$refs.mdlSigendPdfViewer.hide();
    },
  },
};
</script>
