<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <task-manager-edit-component :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></task-manager-edit-component>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import TaskManagerEditComponent from "../components/TaskManagerEditComponent.vue";
export default {
    components: { SaPageLayout, TaskManagerEditComponent },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            linkback: "/taskmanager",
            jsonData: {},
            showModalLoading: false,
            pathResource: "/gesantask",
        };
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        onBeforeLoadData() {
            let me = this;
            me.showModalLoading = true;
        },
        onAfterLoadData() {
            let me = this;
            me.showModalLoading = false;
        },
    },
};
</script>

<style></style>
