<template>
  <sa-page-layout :btnSaveVisible="true" :btnSaveNotBackVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :linkNotBack="linkNotBack" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" @aftersave="onAfterSave" class="sa-no-space">
    <template slot="table-body">
      <print-report-edit-component ref="PrintReportEditComponent" :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></print-report-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintReportEditComponent from "../components/PrintReportEditComponent.vue";
export default {
  components: { SaPageLayout, PrintReportEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: "/printreport",
      jsonData: {},
      showModalLoading: false,
      pathResource: "/printreporttipi",
      linkNotBack: null,
      id: "-1",
    };
  },
  mounted: function () {
    let me = this;
    let path = this.$route.path.replace(/\/edit\/.*/, "");
    me.linkNotBack = path + "{editid}?";
    me.loadData();
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.PrintReportEditComponent.loadData();
    },
    onAfterSave(data) {
      let me = this;
      me.jsonData = data;
      if (me.id === "-1") {
        me.id = data.id;
      }
      me.loadData();
    },
  },
};
</script>

<style></style>
