<template>
    <div v-if="idDashboard !== null">
        <data-analysis-dashboard-view-component :idDashboard="idDashboard"></data-analysis-dashboard-view-component>
    </div>
    <div v-else class="sa-page-dashboard-no-dashboard">
        <div class="sa-page-dashboard-no-dashboard-message">Nessuna Dashboard Configurata</div>
    </div>
</template>

<script>
import DataAnalysisDashboardViewComponent from "../components/DataAnalysisDashboardViewComponent.vue";
export default {
    components: { DataAnalysisDashboardViewComponent },
    data() {
        return {
            idDashboard: "1",
        };
    },
};
</script>

<style></style>
