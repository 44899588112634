<template>
    <div class="sa-page-dashboard">
        <div class="sa-page-dashboard-header">
            <b-row>
                <b-col v-for="headerElement in dashboard.headerElements" :key="headerElement.id" :cols="headerElement.cols" :xs="headerElement.xs" :sm="headerElement.sm" :md="headerElement.md" :lg="headerElement.lg" :xl="headerElement.xl">
                    <b-overlay :show="headerElement.showOverlay" rounded="sm">
                        <b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" :header="headerElement.title" header-tag="header" footer-tag="footer" title="">
                            <b-card-body>
                                <b-card-text class="text-right sa-dashboard-data-text">{{ headerElement.value }}</b-card-text>
                            </b-card-body>
                        </b-card>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
        <div class="sa-page-dashboard-body">
            <b-row>
                <b-col v-for="bodyElement in dashboard.bodyElements" :key="bodyElement.id" :cols="bodyElement.cols" :xs="bodyElement.xs" :sm="bodyElement.sm" :md="bodyElement.md" :lg="bodyElement.lg" :xl="bodyElement.xl">
                    <b-overlay :show="bodyElement.showOverlay" rounded="sm">
                        <b-card class="sa-card" style="height: 100%" :header="bodyElement.title" header-tag="header" footer-tag="footer" title="">
                            <b-card-body>
                                <div v-if="bodyElement.type === 'graphic'" class="sa-page-dashboard-body-graphic" style="height: 300px">
                                    <generic-chart-component xAxisType="value" :chartData="bodyElement.chartData"></generic-chart-component>
                                </div>
                                <div v-else>
                                    <p>{{ bodyElement }}</p>
                                    <b-card-text class="text-right sa-dashboard-data-text">{{ bodyElement.value }}</b-card-text>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-overlay>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import GenericChartComponent from "../../utility/components/GenericChartComponent.vue";
export default {
    props: {
        idDashboard: String,
    },
    components: { GenericChartComponent },
    created() {
        if (this.idDashboard) {
            this.id = this.idDashboard;
        } else {
            this.id = this.$route.params.id;
        }
    },
    mounted() {
        this.loadData();
    },
    data() {
        return {
            id: null,
            pathResource: "/dataanalysisdashboard",
            pathResourceData: "/dataanalysisdatabaseinfo/eseguiquery",
            pie: null,
            dashboard: {
                headerElements: [],
                bodyElements: [],
            },
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    let dashboard = JSON.parse(response.data.data.dashboard);
                    // me.show = new Array(dashboard.headerElements.length).fill(true);
                    dashboard.headerElements.forEach((element) => {
                        me.setValue(element);
                    });
                    dashboard.bodyElements.forEach((element) => {
                        if (element.type === "graphic") {
                            element.chartData = { titolo: "", unitaMisura: "", xAxis: [{ type: "value" }], series: [] };
                        }
                        me.setValue(element);
                    });

                    me.dashboard = dashboard;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        setValue(obj) {
            let me = this;
            obj.showOverlay = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceData + "/" + obj.idQuery;
            axios
                .get(link)
                .then((response) => {
                    let objData = null;
                    let series = [];
                    switch (obj.type) {
                        case "numeric":
                            objData = response.data.data[0];
                            for (let property in objData) {
                                obj.value = objData[property];
                            }
                            break;
                        case "graphic":
                            obj.data = response.data.data;
                            objData = me.getDataChart(obj);
                            for (let label in objData.series) {
                                let serie = {
                                    name: label,
                                    type: "bar",
                                    barGap: 0,
                                    label: label,
                                    emphasis: {
                                        focus: "series",
                                    },
                                    data: [],
                                };

                                objData.xvalue.forEach((value) => {
                                    if (objData.series[label].data[value]) {
                                        serie.data.push(objData.series[label].data[value]);
                                    } else {
                                        serie.data.push(0);
                                    }
                                });
                                series.push(serie);
                            }

                            obj.chartData = {
                                titolo: "",
                                toolbox: {
                                    show: true,
                                    orient: "vertical",
                                    left: "right",
                                    top: "center",
                                    feature: {
                                        mark: { show: true },
                                        dataView: { show: false, readOnly: false },
                                        magicType: { show: true, type: ["line", "bar", "stack"] },
                                        restore: { show: false },
                                        saveAsImage: { show: true },
                                    },
                                },
                                xAxis: [
                                    {
                                        type: "category",
                                        axisTick: { show: false },
                                        data: objData.xvalue,
                                    },
                                ],
                                series: series,
                                unitaMisura: "",
                            };
                            break;
                    }

                    obj.showOverlay = false;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        chartEvents() {},
        getDataChart(obj) {
            let tmpXValue = [];
            let returnValue = { xvalue: [], series: [] };
            obj.data.forEach((element) => {
                for (let key in element) {
                    if (obj.fieldGroup === key) {
                        tmpXValue.push(element[key]);
                    }
                    if (obj.fieldLabel === key) {
                        if (!returnValue.series[element[key]]) {
                            returnValue.series[element[key]] = { title: element[key], data: [] };
                        }
                        let keyGroup = element[obj.fieldGroup];
                        returnValue.series[element[key]].data[keyGroup] = element[obj.fieldValue];
                    }
                }
            });
            returnValue.xvalue = [...new Set(tmpXValue)];
            return returnValue;
        },
    },
};
</script>

<style></style>
