<template>
    <sa-page-layout ref="pgaView" :btnAnnullaVisible="true" :btnEditVisible="true" :pathResource="pathResource" :data="data" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <div style="float: right">
                <print-component :reportName="reportName" :data="items"></print-component>
            </div>
        </template>
        <template slot="table-filter">
            <div style="max-height: 18rem; overflow: auto;">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblPackCode") }}:</strong> 
                        <br />
                        {{data.codiceSacca}}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblPackType") }}:</strong>
                        <br>
                        {{data.tipologiaSacca}}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblBloodGroup") }}:</strong>
                        <br/>
                        {{data.gruppoSanguigno}}{{onModificaFattoreRh(data.fattoreRh)}}
                    </b-col>
                     <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblTypeOfBlood") }}</strong>
                        <br>
                        {{data.tipologia}}
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblHB") }}:</strong><br>
                        {{data.hb}}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblPT") }}:</strong> <br>                       
                        {{data.pt}}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblINR") }}:</strong><br>
                        {{data.inr}}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblAPTT") }}:</strong><br/>
                        {{data.aptt}}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblPIT") }}:</strong><br>
                        {{data.pit}}
                    </b-col> 
                </b-row>
                <hr>
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>{{ getLabelTraduora("sequenziamento.lblstatoAttuale") }}:</strong><br>
                        {{ data.statoAttuale }}
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblExpirationDate") }}:</strong><br>
                        {{ formatDate(data.dataScadenza) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblDestinationDepartment") }}:</strong><br>
                        {{ data.repartoDestinazione }}
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblStockRequestNumber") }}:</strong><br>
                        <router-link class="sa-edit-link" :to="'/bancaematicarichiestascorte/view/' + data.bancaEmaticaTrasfusioneRichiestaScorte.id"> {{ data.bancaEmaticaTrasfusioneRichiestaScorte.numeroRichiesta }} del {{ formatDate(data.bancaEmaticaTrasfusioneRichiestaScorte.dataRichiesta) }} </router-link>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>{{ getLabelTraduora("bancaEmatica.lblDepartmentRequestNumber") }}:</strong><br>
                        <router-link class="sa-edit-link" :to="'/bancaematicarichiestereparto/view/' + data.bancaEmaticaTrasfusioneRichiestaReparto.id"> {{ data.bancaEmaticaTrasfusioneRichiestaReparto.numeroRichiesta }} del {{ formatDate(data.bancaEmaticaTrasfusioneRichiestaReparto.dataRichiesta) }} </router-link>
                    </b-col>
                </b-row>
                <hr>
            </div>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" lg="3">
                    <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6" lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs class="sa-tabs" content-class="mt-3" @activate-tab="onActiveTabs" fill>
                <div class="sa-tab-scroll">
                    <b-tab :title="getLabelTraduora('bancaEmatica.lblBloodBankState')" active>
                        <b-table sticky-header ref="table" id="tblDettaglio" responsive="xl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
                        </b-table>
                    </b-tab>
                </div>
            </b-tabs>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins:[UtilityMixin],
    components: { SaPageLayout, PrintComponent },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            linkback: "/bancaematica",
            linkrichiestereparti: "",
            reportName: "ReportDatiSaccaBancaEmatica",
            linkedit: null,
            search: {},
            elaborazione: false,
            currentPage: 1,
            totalRows: 1,
            perPage: 1500,
            pathResource: "/bancheematiche",
            fields: [
                {
                    label: this.getLabelTraduora("bancaEmatica.lblIndexNumber"),
                    key: "index",
                    sortable: true,
                    thStyle: "width: auto",
                },
                {
                    label: this.getLabelTraduora("bancaEmatica.lblState"),
                    key: "stato",
                    sortable: true,
                    thStyle: "width: auto",
                },
                {
                    label: this.getLabelTraduora("bancaEmatica.lblStateDate"),
                    key: "statoDataOra",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: auto",
                    sortable: true,
                },
                {
                    label: this.getLabelTraduora("bancaEmatica.lblNotes"),
                    key: "note",
                    thStyle: "width: auto",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 18rem", tdClass: "text-center" },
            ],
            items: [],
            data: { bancaEmaticaTrasfusioneRichiestaScorte: { numeroRichiesta: "--", dataRichiesta: "" }, bancaEmaticaTrasfusioneRichiestaReparto: { numeroRichiesta: "--", dataRichiesta: "" } },
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/bancaematica/edit/" + me.id;
        me.loadData();
    },
    update: function() {
        console.log("Update");
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        disabledAccept(value) {
            if (value) {
                return false;
            } else {
                return true;
            }
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.data = response.data.data;
                    me.items = response.data.data.bancaEmaticaStato;
                    me.richiesteReparti = response.data.data.bancaEmaticaTrasfusioneRichiestaReparto;
                    me.richiesteScorte = response.data.data.bancaEmaticaTrasfusioneRichiestaScorte;
                    //me.$refs["SaPageList"].close();
                    me.perPage = me.data.length;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },

        onActiveTabs(newTabIndex) {
            let me = this;
            if (newTabIndex === 1) {
                me.loadData();
            }
            if (newTabIndex === 2) {
                me.loadData();
            }
        },
        onSubmit() {
            let me = this;
            console.log(me.search);
        },
        onModificaFattoreRh(valore) {
            if (valore === "POSITIVO") {
                return "+";
            } else if(valore === "NEGATIVO") {
                return "-";
            }else{
                return"";
            }
        },
    },
};
</script>
