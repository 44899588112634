<template>
  <eventi-view-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData" :linkPrintData="linkPrintData">
    <template slot="tab-content-event">
      <derma-visita-view-component :jsonData="jsonData" @update="onUpdateJsonData"></derma-visita-view-component>
    </template>
  </eventi-view-component>
</template>
<script>
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import DermaVisitaViewComponent from "../components/DermaVisitaViewComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { EventiViewComponent, DermaVisitaViewComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },
  data() {
    return {
      linkback: null,
      titoloEvento: "Visita Dermatologica",
      pathResource: "/dermavisite",
      pathRestValidation: "/validation",
      linkPrintData: null,
      id: "-1",
      isFirmato: false,
      childKey: 0,
      tabIndex: 0,
      showModalLoading: false,
      jsonData: {
        dataEvento: new Date().getTime(),
        conclusioni: "",
      },
    };
  },
  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      if (me.jsonData.firma) {
        me.btnFirmaVisible = false;
      } else {
        me.btnFirmaVisible = true;
      }
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>
