<template>
  <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
    <template slot="tab-content-event">
      <ecg-visita-edit-component :jsonData="jsonData" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></ecg-visita-edit-component>
    </template>
  </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EcgVisitaEditComponent from "../components/EcgVisitaEditComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { EventiEditComponent, EcgVisitaEditComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },
  data() {
    return {
      linkback: null,
      titoloEvento: "Visita Ecg",
      pathResource: "/ecgvisite",
      pathRestValidation: "/validation",
      id: "-1",
      isFirmato: false,
      childKey: 0,
      tabIndex: 0,
      showModalLoading: false,
      jsonData: {
        dataEvento: new Date().getTime(),
        conclusioni: "",
      },
    };
  },
  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>
