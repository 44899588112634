<template>
  <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Codice Documento:</label>
        <span class="sa-data">{{ jsonData.codiceTipoDocumento }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Descrizione:</label>
        <span class="sa-data">{{ jsonData.descrizione }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">MasterReport:</label>
        <span class="sa-data">{{ jsonData.master }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">SubReport:</label>
        <span class="sa-data">{{ jsonData.subreport }}</span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      id: "-1",
      pathResource: "/printreporttipi",
      jsonData: {},
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;

    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadData() {
      let me = this;
      this.$emit("beforeLoadData");
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios.get(link + me.id).then((response) => {
        me.jsonData = response.data.data;
        this.$emit("afterLoadData");
      });
    },
  },
};
</script>

<style></style>
