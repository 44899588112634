import DataAnalysisTablesListPage from "./pages/DataAnalysisTablesListPage.vue";
import DataAnalysisTablesEditPage from "./pages/DataAnalysisTablesEditPage.vue";
import DataAnalysisQueryListPage from "./pages/DataAnalysisQueryListPage.vue";
import DataAnalysisQueryEditPage from "./pages/DataAnalysisQueryEditPage.vue";
import DataAnalysisDashboardListPage from "./pages/DataAnalysisDashboardListPage.vue";
import DataAnalysisDashboardViewPage from "./pages/DataAnalysisDashboardViewPage.vue";

export default {
    DataAnalysisTablesListPage,
    DataAnalysisTablesEditPage,
    DataAnalysisQueryListPage,
    DataAnalysisQueryEditPage,
    DataAnalysisDashboardListPage,
    DataAnalysisDashboardViewPage,
};
