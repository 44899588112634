<template>
  <sa-page-layout :btnBackVisible="true" :linkback="linkback" :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <protocollo-utenti-list-component ref="ProtocolloUtentiListComponent" @afterLoadData="onAfterLoadData"></protocollo-utenti-list-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import ProtocolloUtentiListComponent from "../components/ProtocolloUtentiListComponent.vue";

export default {
  components: { SaPageLayout, ProtocolloUtentiListComponent },
  data() {
    return {
      btnNewVisible: true,
      pathResource: "",
      linkedit: "/protocolloutenti",
      linkback: "/protocollomoduli",
      showModalLoading: false,
      listKey: 0,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.ProtocolloUtentiListComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
