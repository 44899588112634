import BancaEmatica from "../";

const routes = [
    { name: "BancaEmaticaList", path: "/bancaematica", component: BancaEmatica.BancaEmaticaList, meta: { title: "bancaematica.lblTitle" } },
    { name: "BancaEmaticaView", path: "/bancaematica/view/:id", component: BancaEmatica.BancaEmaticaView, meta: { title: "bancaematica.lblTitle" } },
    { name: "BancaEmaticaEdit", path: "/bancaematica/edit/:id", component: BancaEmatica.BancaEmaticaEdit, meta: { title: "bancaematica.lblTitle" } },
    { name: "BancaEmaticaRichiestaScorteList", path: "/bancaematicarichiestascorte", component: BancaEmatica.BancaEmaticaRichiestaScorteList, meta: { title: "bancaematica.lblTitleScorte" } },
    { name: "BancaEmaticaRichiestaScorteView", path: "/bancaematicarichiestascorte/view/:id", component: BancaEmatica.BancaEmaticaRichiestaScorteView, meta: { title: "bancaematica.lblTitleScorte" } },
    { name: "BancaEmaticaRichiestaScorteEdit", path: "/bancaematicarichiestascorte/edit/:id", component: BancaEmatica.BancaEmaticaRichiestaScorteEdit, meta: { title: "bancaematica.lblTitleScorte" } },
    { name: "BancaEmaticaRichiesteRepartoList", path: "/bancaematicarichiestereparto", component: BancaEmatica.BancaEmaticaRichiesteRepartoList, meta: { title: "bancaematica.lblTitleReparto" } },
    { name: "BancaEmaticaRichiesteRepartoEdit", path: "/bancaematicarichiestereparto/edit/:id", component: BancaEmatica.BancaEmaticaRichiesteRepartoEdit, meta: { title: "bancaematica.lblTitleReparto" } },
    { name: "BancaEmaticaRichiesteRepartoView", path: "/bancaematicarichiestereparto/view/:id", component: BancaEmatica.BancaEmaticaRichiesteRepartoView, meta: { title: "bancaematica.lblTitleReparto" } },
    { name: "BancaEmaticaList", path: "/bancaematicareparti", component: BancaEmatica.BancaEmaticaRepartiList, meta: { title: "bancaematica.lblTitleDepartaments" } },
    { name: "BancaEmaticaRepartiEdit", path: "/bancaematicareparti/edit/:id", component: BancaEmatica.BancaEmaticaRepartiEdit, meta: { title: "bancaematica.lblTitleDepartaments" } },
    { name: "BancaEmaticaRepartiView", path: "/bancaematicareparti/view/:id", component: BancaEmatica.BancaEmaticaRepartiView, meta: { title: "bancaematica.lblTitleDepartaments" } },
    { name: "BancaEmaticaOperatoriList", path: "/bancaematicaoperatori", component: BancaEmatica.BancaEmaticaOperatoriList, meta: { title: "bancaematica.lblTitleOperators" } },
    { name: "BancaEmaticaOperatoriEdit", path: "/bancaematicaoperatori/edit/:id", component: BancaEmatica.BancaEmaticaOperatoriEdit, meta: { title: "bancaematica.lblTitleOperators" } },
    { name: "BancaEmaticaOperatoriView", path: "/bancaematicaoperatori/view/:id", component: BancaEmatica.BancaEmaticaOperatoriView, meta: { title: "bancaematica.lblTitleOperators" } },
    { name: "BancaEmaticaOperatoriList", path: "/bancaematicaoperatoretipi", component: BancaEmatica.BancaEmaticaTipiOperatoriList, meta: { title: "bancaematica.lblTitleOperators" } },
    { name: "BancaEmaticaOperatoriEdit", path: "/bancaematicaoperatoretipi/edit/:id", component: BancaEmatica.BancaEmaticaTipiOperatoriEdit, meta: { title: "bancaematica.lblTitleOperators" } },
    { name: "BancaEmaticaOperatoriView", path: "/bancaematicaoperatoretipi/view/:id", component: BancaEmatica.BancaEmaticaTipiOperatoriView, meta: { title: "bancaematica.lblTitleOperators" } },
    { name: "BancaEmaticaTotali", path: "/bancaematicatotali", component: BancaEmatica.BancaEmaticaTotali, meta: { title: "Banca Ematica Totali" } },
    { name: "BancaEmaticaRichiesteRepartiTotaliList", path: "/bancaematicarichiesterepartitotali", component: BancaEmatica.BancaEmaticaRichiesteRepartiTotaliList, meta: { title: "Banca Ematica Richieste Reparti" } },
        

];

export default routes;
