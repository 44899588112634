<template>
    <div style="height: 100%">
        <div style="width: 100%; height: 100%"><v-chart :option="pie" autoresize @rendered="chartEvents" /></div>
    </div>
</template>

<script>
import "echarts";
import ECharts from "vue-echarts";
import "echarts/lib/component/toolbox";
export default {
    props: {
        chartId: {
            type: String,
        },
        chartData: {
            type: Object,
            default: function () {
                return { titolo: "", unitaMisura: "", xAxis: [], series: [] };
            },
        },
    },
    components: {
        "v-chart": ECharts,
    },
    mounted() {
        let me = this;
        me.setPie();
    },
    data() {
        return {
            fontSize: 12,
            pie: null,
        };
    },
    watch: {
        chartData() {
            let me = this;
            me.setPie();
        },
    },
    methods: {
        chartEvents() {
            this.fontSize = 1;
            if (window.innerWidth > 1200) {
                // this.pie.title.textStyle.fontSize = 12;
            } else {
                // this.pie.title.textStyle.fontSize = 6;
            }
        },
        setPie() {
            let me = this;

            let toolbox = {
                show: true,
                feature: {
                    dataZoom: {
                        yAxisIndex: "none",
                    },
                    dataView: { readOnly: false },
                    magicType: { type: ["line", "bar"] },
                    restore: {},
                    saveAsImage: {},
                },
            };

            if (me.chartData.toolbox) {
                toolbox = me.chartData.toolbox;
            }

            me.pie = {
                title: {
                    text: me.chartData.titolo,
                    textStyle: { fontSize: 14, fontWeight: 400 },
                },
                tooltip: {
                    trigger: "axis",
                },
                legend: {},
                toolbox: toolbox,
                xAxis: me.chartData.xAxis,
                yAxis: {
                    min: 0,
                    type: "value",
                    axisLabel: {
                        formatter: "{value} " + me.chartData.unitaMisura,
                    },
                    axisLine: {
                        onZero: false,
                    },
                },
                series: me.chartData.series,
            };
        },
    },
};
</script>

<style></style>
