<template>
  <sa-page-layout ref="pgaView" :btnBackVisible="true" :btnEditVisible="true" :data="data" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <div style="float: right">
        <print-component :reportName="reportName" :data="data"></print-component>
      </div>
    </template>
    <template slot="table-filter">
      <div style="max-height: 18rem; overflow: auto;">
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{getLabelTraduora("bancaEmatica.lblRequestNumber")}}:</strong><br />
            {{ data.numeroRichiesta }} del {{ formatDate(data.dataRichiesta) }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{getLabelTraduora("bancaEmatica.lblRequestPacksNumber")}}:</strong><br />
            {{ data.numeroSaccheRichieste }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{getLabelTraduora("sequenziamento.lblstatoAttuale")}}:</strong><br />
            {{ data.statoAttuale }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{getLabelTraduora("bancaEmatica.lblTypeOfBlood")}}:</strong><br />
            {{ data.tipologia }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{getLabelTraduora("bancaEmatica.lblPackType")}}:</strong><br />
            {{ data.tipologiaSacca }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{getLabelTraduora("bancaEmatica.lblBloodGroup")}}:</strong><br />
            {{ data.gruppoSanguigno }} {{ onModificaFattoreRh(data.fattoreRh) }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <strong>{{getLabelTraduora("bancaEmatica.lblHB")}}:</strong><br />
            {{ data.hb }}
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <strong>{{getLabelTraduora("bancaEmatica.lblPT")}}:</strong> <br />
            {{ data.pt }}
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <strong>{{getLabelTraduora("bancaEmatica.lblINR")}}:</strong><br />
            {{ data.inr }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{getLabelTraduora("bancaEmatica.lblAPTT")}}:</strong><br />
            {{ data.aptt }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{getLabelTraduora("bancaEmatica.lblPIT")}}:</strong><br />
            {{ data.pit }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{getLabelTraduora("bancaEmatica.lblOperator")}}:</strong><br />
            {{ data.nomeOperatore }} {{ data.cognomeOperatore }}
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{getLabelTraduora("bancaEmatica.lblMedic")}}:</strong><br />
            {{ data.nomeMedico }} {{ data.cognomeMedico }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{getLabelTraduora("bancaEmatica.lblReasonRequest")}}:</strong><br />
            {{ data.motivoRichiesta }}
          </b-col>
        </b-row>
        <hr />
      </div>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6" lg="3">
          <p>{{getLabelTraduora("ambulatori.lblTotalRecords")}}: {{ rows }}</p>
        </b-col>
        <b-col cols="6" lg="9">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <b-tabs class="sa-tabs" content-class="mt-3" @activate-tab="onActiveTabs" fill>
        <div class="sa-tab-scroll">
          <b-tab :title="getLabelTraduora('bankhematic.lblTitleScorte')">
            <b-table
              sticky-header
              id="richiesteScorteStato"
              responsive="xl"
              stacked="xl"
              striped
              hover
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              sort-icon-left
              head-variant="light"
              class="sa-b-table"
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
            </b-table>
          </b-tab>
        </div>
      </b-tabs>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{getLabelTraduora("ambulatori.lblTotalRecords")}}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins:[UtilityMixin],
  components: { SaPageLayout, PrintComponent },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      showModalLoading: false,
      pathResource: "/bancheematichetrasfusionirichiestescorte",
      linkback: "/bancaematicarichiestascorte",
      reportName: "ReportRichiestaScorteSingola",
      linkedit: null,
      search: {},
      elaborazione: false,
      currentPage: 1,
      totalRows: 1,
      perPage: 1500,
      fields: [
        {
          label: "N.",
          key: "index",
          sortable: true,
          thStyle: "width: auto",
        },
        {
          label: "Stato",
          key: "stato",
          sortable: true,
          thStyle: "width: auto",
        },
        {
          label: "Stato Data e Ora",
          key: "statoDataOra",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: auto",
          sortable: true,
        },
        {
          label: "Note",
          key: "note",
          thStyle: "width: auto",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: "width: 18rem",
          tdClass: "text-center",
        },
      ],
      items: [],
      richiesteScorte: [],
      data: {},
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/bancaematicarichiestascorte/edit/" + me.id;
    me.loadData();
  },
  update: function() {
    console.log("Update");
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.isBusy = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.data = response.data.data;
          me.richiesteScorte = response.data.data.bancaEmaticaTrasfusioneRichiestaScortaBean;
          me.items = response.data.data.bancaEmaticaTrasfusioneRichiestaScorteStato;
          me.perPage = me.data.length;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onActiveTabs(newTabIndex) {
      let me = this;
      if (newTabIndex === 1) {
        me.loadData();
      }
    },
    onSubmit() {
      let me = this;
      console.log(me.search);
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else if (valore === "NEGATIVO") {
        return "-";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="css">
/*.sa-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.sa-tabs .tab-content {
    height: 100%;
    overflow: auto;
}
.sa-fieldset-intestazione legend {
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;
}
.sa-fieldset-intestazione p {
    margin-bottom: 0.5em;
    line-height: 1.1em;
    font-weight: 800;
}
.sa-fieldset-intestazione label {
    padding-right: 0.3em;
    margin-bottom: 1.5em;
}*/
</style>
