<template>
  <div>
    <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
          <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Num. Progressivo</label>
          <span class="sa-data">{{ jsonData.progressivo }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblOrigin") }}</label>
          <span class="sa-data">{{ jsonData.provenienza }}</span>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecoendoscopia.lblReasonExam") }}</label>
                    <span class="sa-data">{{ jsonData.strumento }}</span>
                </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblBiliaryTractExt") }}</label>
                    <span class="sa-data">{{ jsonData.vieBiliariExtraepatiche }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblLiver") }}</label>
                    <span class="sa-data">{{ jsonData.fegato }}</span>
                </b-col> -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ this.$i18n.t("patient.ecoendoscopia.lblReasonExam") }}</label>
          <span class="sa-data-justify">{{ jsonData.motivoEsame }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Diagnosi</label>
          <span class="sa-data-justify">{{ jsonData.diagnosi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Corpo Referto</label>
          <span class="sa-data-justify">{{ jsonData.corpoReferto }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Consigli Terapeutici</label>
          <span class="sa-data-justify">{{ jsonData.consigliTerapeutici }}</span>
        </b-col>

        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblGallbladder") }}</label>
                    <span class="sa-data">{{ jsonData.colecisti }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Premedicazione</label>
                    <span class="sa-data">{{ jsonData.premedicazione }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblPancreas") }}</label>
                    <span class="sa-data">{{ jsonData.pancreas }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblSpleen") }}</label>
                    <span class="sa-data">{{ jsonData.milza }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblLeftKidney") }}</label>
                    <span class="sa-data">{{ jsonData.reneSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblRightKidney") }}</label>
                    <span class="sa-data">{{ jsonData.reneDx }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblAxisSplenoPortal") }}</label>
                    <span class="sa-data">{{ jsonData.asseSplenoPortale }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblVarious") }}</label>
                    <span class="sa-data-justify">{{ jsonData.varie }}</span>
                </b-col> -->
      </b-row>
    </b-card>
    <eventi-operatori-component :operatori="jsonData.listaOperatori" :isEdit="isEdit"></eventi-operatori-component>
  </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import axios from "axios";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
  components: { EventiOperatoriComponent },
  mixins: [UtilityMixin],
  props: {
    idEvento: {
      Type: String,
      require: true,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return { isEdit: false, pathResource: "/gastroecografiaaddominale", id: null, jsonData: {} };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.idEvento === "-1" || me.idEvento === null) {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.idEvento;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
  },
};
</script>

<style></style>
./EcografiaAddominaleViewComponent.vue
