<template>
  <sa-page-layout ref="pgaView" :btnBackVisible="true" :btnEditVisible="true" :pathResource="pathResource" :data="data" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <div style="float: right">
        <print-component :reportName="reportName" :data="data"></print-component>
      </div>
    </template>
    <template slot="table-filter">
      <div style="max-height: 18rem; overflow: auto;">
        <legend>{{ getLabelTraduora("bancaEmatica.lblRequestData") }}:</legend>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("bancaEmatica.lblRequestNumber") }}:</strong><br />
            {{ data.numeroRichiesta }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("sequenziamento.lblstatoAttuale") }}:</strong><br />
            {{ data.statoAttuale }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("sequenziamento.lblstatoAttualeDataOra") }}:</strong><br />
            {{ formatDate(data.statoAttualeDataOra) }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblRequestState") }}:</strong><br />
            {{ data.statoRichiesta }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblRequestDate") }}:</strong><br />
            {{ formatDate(data.dataRichiesta) }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblOperatorRegistrationNumber") }}:</strong><br />
            {{ data.matricolaOperatore }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblOperatorName") }}:</strong><br />
            {{ data.nomeOperatore }} {{ data.cognomeOperatore }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblMedicRegistrationNumber") }}:</strong><br />
            {{ data.matricolaMedico }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblMedic") }}:</strong><br />
            {{ data.nomeMedico }} {{ data.cognomeMedico }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblRequestReason") }}:</strong><br />
            {{ data.motivoRichiesta }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblRequestPacksNumber") }}:</strong><br />
            {{ data.numeroSaccheRichieste }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("bancaEmatica.lblPackType") }}:</strong><br />
            {{ data.tipologiaSacca }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("bancaEmatica.lblBloodGroup") }}:</strong><br />
            {{ data.gruppoSanguigno }} {{ onModificaFattoreRh(data.fattoreRh) }}
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>{{ getLabelTraduora("bancaEmatica.lblTypeOfBlood") }}</strong
            ><br />
            {{ data.tipologia }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <strong>{{ getLabelTraduora("bancaEmatica.lblHB") }}:</strong><br />
            {{ data.hb }}
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <strong>{{ getLabelTraduora("bancaEmatica.lblPT") }}:</strong> <br />
            {{ data.pt }}
          </b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2">
            <strong>{{ getLabelTraduora("bancaEmatica.lblINR") }}:</strong><br />
            {{ data.inr }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblAPTT") }}:</strong><br />
            {{ data.aptt }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblPIT") }}:</strong><br />
            {{ data.plt }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblPre-TransfusionTub") }}:</strong><br />
            {{ data.provettaPretrasfusionale }}
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblStructureCode") }}:</strong><br />
            {{ data.codiceStruttura }}
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblDepartment") }}:</strong><br />
            {{ data.reparto }}
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblUrgencyDegree") }}:</strong><br />
            {{ data.gradoUrgenza }}
          </b-col>
        </b-row>
        <hr />
        <legend>{{ getLabelTraduora("teleconsulto.lblPatientData") }}:</legend>
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("ambulatori.lblName") }}:</strong><br />
            {{ data.nomePaziente }} {{ data.cognomePaziente }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patients.lblBirthDate") }}:</strong><br />
            {{ formatDate(data.dataNascitaPaziente) }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("patients.lblGender") }}:</strong><br />
            {{ data.sessoPaziente }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblBirthPlace") }}:</strong><br />
            {{ data.luogoNascitaPaziente }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblRecoveryDate") }}:</strong><br />
            {{ formatDate(data.dataRicovero) }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblMedicalRecords") }}:</strong><br />
            {{ data.cartellaClinica }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblPreviousTransfusion") }}:</strong><br />
            {{ data.trasfusioniPregresse }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblLastTransfusionDate") }}:</strong><br />
            {{ formatDate(data.dataUltimaTrasfusione) }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblTransfusionReactions") }}</strong
            ><br />
            {{ data.reazioniTrasfusionali }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblLastReactionDate") }}:</strong><br />
            {{ formatDate(data.dataUltimaReazioneTrasfusionale) }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblPreviousChildBirthAbortion") }}:</strong><br />
            {{ data.partiAbortiPregressi }}
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>{{ getLabelTraduora("bancaEmatica.lblLastChildBirthAbortion") }}:</strong><br />
            {{ formatDate(data.dataUltimoAbbortoParto) }}
          </b-col>
        </b-row>
        <hr />
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="12">
            <user-information :data="data"></user-information>
            <hr />
          </b-col>
          <!-- <b-card header="User Information">
                        <blockquote class="blockquote mb-0">
                            <user-information :data="data"></user-information>
                        </blockquote>
                    </b-card> -->
        </b-row>
      </div>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6" lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6" lg="9">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>

    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="b-table">
          <b-table sticky-header id="richiesteReparti" responsive="xl" stacked="xl" striped hover :items="items" :fields="fieldsRichiesteReparti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
        </div>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UserInformation from "../../utenti/components/UserInformation.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { UserInformation, SaPageLayout, PrintComponent },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      showModalLoading: false,
      pathResource: "/bancaematicatrasfusionirichiestereparti",
      linkback: "/bancaematicarichiestereparto",
      linkrichiestereparti: "",
      reportName: "RichiestaRepartoSingola",
      linkedit: null,
      search: {},
      elaborazione: false,
      currentPage: 1,
      totalRows: 1,
      perPage: 1500,
      fieldsRichiesteReparti: [
        {
          label: "N.",
          key: "index",
          sortable: true,
          thStyle: "width: auto",
        },
        {
          label: "Stato",
          key: "stato",
          sortable: true,
          thStyle: "width: auto",
        },
        {
          label: "Stato Data e Ora",
          key: "statoDataOra",
          formatter: (value) => {
            if (!value) {
              return "- - -";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: auto",
          sortable: true,
        },
        {
          label: "Data Creazione",
          key: "createDate",
          formatter: (value) => {
            if (!value) {
              return "- - -";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: auto",
          sortable: true,
        },
        {
          label: "Note",
          key: "note",
          thStyle: "width: auto",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: "width: 7rem",
          tdClass: "text-center",
        },
      ],

      items: [],
      richiesteReparti: [],
      data: {},
    };
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/bancaematicarichiestereparto/edit/" + me.id;
    me.loadData();
  },
  update: function() {
    console.log("Update");
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.isBusy = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.data = response.data.data;
          me.items = response.data.data.bancaEmaticaTrasfusioneRichiestaRepartoStato;
          me.perPage = me.data.length;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },

    onSubmit() {
      let me = this;
      console.log(me.search);
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else if (valore === "NEGATIVO") {
        return "-";
      } else {
        return "";
      }
    },
  },
};
</script>
