<template>
  <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <div style="float: right">
        <print-component :reportName="reportName" :data="items"></print-component>
      </div>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-input v-model="filtro.numeroRichiesta" type="search" id="nome" :placeholder="getLabelTraduora('bancaEmatica.lblRequestNumber')"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null"></b-form-select>
          </b-col>
        </b-row>
        <b-row style="margin-top: 5px; margin-bottom: 5px;">
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>

    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <!-- <b-table sticky-header ref="table" :filter="filter" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table"> -->
        <b-table
          sticky-header
          ref="table"
          responsive
          stacked="xl"
          striped
          hover
          :filter="filter"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          head-variant="light"
          class="sa-b-table"
        >
          <template v-slot:cell(numeroRichiesta)="{ item }">
            <router-link class="sa-edit-link" :to="'/bancaematicarichiestascorte/view/' + item.id">
              {{ item.numeroRichiesta }}
            </router-link>
            <br />
            {{getLabelTraduora("bancaEmatica.lblRequestDate")}}: {{ formatDate(item.dataRichiesta) }}
          </template>
          <template v-slot:cell(medico)="{ item }">
            <p>{{ item.nomeMedico }} {{ item.cognomeMedico }}</p>
          </template>
          <template v-slot:cell(operatore)="{ item }">
            <p>{{ item.nomeOperatore }} {{ item.cognomeOperatore }}</p>
          </template>
          <template v-slot:cell(gruppoSanguigno)="{ item }">
            <a>
              {{ item.gruppoSanguigno }}
              {{ onModificaFattoreRh(item.fattoreRh) }}
            </a>
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" variant="outline-secondary width-sm no-text" style="margin-right:3px" @click="onCambioStato(row.item, row.index, $event.target)">
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal ref="tstModal" id="my-modal" @ok="onOk">
        <b-row>
          <b-form-select id="inline-form-custom-select-pref" v-model="data.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
        </b-row>
        <b-row>
          <b-form-textarea id="textarea-no-resize" v-model="data.note" :placeholder="getLabelTraduora('bancaEmatica.lblNotes')" rows="3" no-resize></b-form-textarea>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins:[UtilityMixin],
  components: { PrintComponent, SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      pathResource: "/bancheematichetrasfusionirichiestescorte",
      linkedit: "/bancaematicarichiestascorte",
      linkstato: "/bancheematichetrasfusionirichiestescortestatitipi",
      linkgrupposanguigno: "/bancheematichegruppisanguigni",
      linkfattorerh: "/bancaematicafattorirh",
      pathResourceAggiornaStato: "/aggiornaStatoScorte",
      reportName: "ReportListaRichiestaScorte",
      showModalLoading: false,
      perPage: 10,
      currentPage: 1,
      note: "",
      filter: null,
      id: "-1",
      filtro: { statoAttuale: null, gruppoSanguigno: null, fattoreRh: null },
      filterOn: [],
      fields: [
        {
          label: "Num. Richiesta",
          key: "numeroRichiesta",
          sortable: true,
        },
        {
          label: "Medico",
          key: "medico",
          sortable: true,
        },
        {
          label: "Operatore",
          key: "operatore",
          sortable: true,
        },
        {
          label: "Tipologia",
          key: "tipologia",
          sortable: true,
          thStyle: "width: 6rem",
        },
        {
          label: "N. Sacche",
          key: "numeroSaccheRichieste",
          thStyle: "width: 6rem",
          sortable: true,
        },
        {
          label: "Tip. Sacca",
          key: "tipologiaSacca",
          sortable: true,
          thStyle: "width: 8rem",
        },
        {
          label: "Gr. Sang.",
          key: "gruppoSanguigno",
          thStyle: "width: 8rem",
          sortable: true,
        },
        // {
        //     label: "HB",
        //     key: "hb",
        //     thStyle: "width: 3rem",
        //     sortable: true,
        // },
        // {
        //     label: "PT",
        //     key: "pt",
        //     thStyle: "width: 3rem",
        //     sortable: true,
        // },
        // {
        //     label: "INR",
        //     key: "inr",
        //     thStyle: "width: 4rem",
        //     sortable: true,
        // },
        // {
        //     label: "APTT",
        //     key: "aptt",
        //     thStyle: "width: 4rem",
        //     sortable: true,
        // },
        // {
        //     label: "PTT",
        //     key: "pit",
        //     thStyle: "width: 4rem",
        //     sortable: true,
        // },
        {
          label: "Stato",
          key: "statoAttuale",
          thStyle: "width: 5rem",
          sortable: true,
        },
        // {
        //     label: "Motivo Ric.",
        //     key: "motivoRichiesta",
        //     thStyle: "width: 9rem",
        //     sortable: true,
        // },
        {
          label: "",
          key: "actions",
          thStyle: "width: 2rem",
        },
        // { class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
      ],
      items: [],
      statoOption: [
        { value: null, text: "-Stato-" },
        { value: "", text: "TUTTI" },
      ],
      gruppoSanguignoOption: [{ value: null, text: "-Gruppo Sanguigno-" }],
      fattoreRhOption: [{ value: null, text: "-Fattore Rh-" }],
      data: {
        stato: null,
        note: "",
        idBancaEmaticaTrasfusioneRichiestaScorte: "",
      },
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadStato();
    me.loadGruppoSanguigno();
    me.loadFattoreRh();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?page=1&forPage=50";
      me.isBusy = true;
      if (me.filtro.statoAttuale === null) {
        me.filtro.statoAttuale = "RICHIESTA";
      }
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          for (let element in me.items) {
            me.items[element]["_cellVariants"] = {};
            if (me.items[element]["dataScadenza"] - 259200 > Date.now()) {
              me.items[element]["_cellVariants"].dataScadenza = "success";
            } else if (me.items[element]["dataScadenza"] - 259200 <= Date.now()) {
              me.items[element]["_cellVariants"].dataScadenza = "danger";
            }

            if (me.items[element]["statoAttuale"] === "DISPONIBILE") {
              me.items[element]["_cellVariants"].statoAttuale = "success";
            }
          }
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onCambioStato(item) {
      let me = this;
      me.$refs.tstModal.show();
      me.data.idBancaEmaticaTrasfusioneRichiestaScorte = item.id;
      me.data.note = item.note;
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onOk() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + me.pathResourceAggiornaStato;
      let jsonData = JSON.stringify(me.data);
      axios.post(link, jsonData).then(() => {
        me.showModalLoading = false;
        me.loadData();
      });
    },
    loadStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkstato;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.statoOption.push({ value: element.valore, text: element.valore });
        });
        me.filtro.statoAttuale = "RICHIESTA";
      });
    },
    loadGruppoSanguigno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    loadFattoreRh() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.fattoreRhOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else if (valore === "NEGATIVO") {
        return "-";
      } else {
        return "";
      }
    },
  },
};
</script>
