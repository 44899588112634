<template>
  <div class="sa-list-component">
    <div class="sa-list-component-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Protocollo:</label>
            <b-form-input v-model="filtro.idProtocollo"></b-form-input>
          </b-col>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
          </b-col>

        </b-row>
      </b-form>
    </div>
    <div class="sa-list-component-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(primaryKey)="{ item }">
            <router-link class="sa-edit-link" :to="'/gedocprotocollo/view/' + item.id">{{ item.primaryKey }}</router-link>
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>


  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        rows: 0,
        perPage: 50,
        currentPage: 1,
        pathResource: "/notificationgedocresponse",
        filtro: { idProtocollo: "" },
        items: [],
        listKey: 0,
        fields: [
           {
            label: "Modulo",
            key: "modulo",
            sortable: true,
          },
          {
            label: "Cod Documento",
            key: "primaryKey",
            sortable: true,
            
          },
          {
            label: "Esito Invocazione",
            key: "esitoInvocazione",
            sortable: true,
          },
          {
            label: "Tipo Documento",
            key: "tipoDocumento",
            sortable: true,
          },
          {
            label: "Cod Protocollo",
            key: "idProtocollo",
            sortable: true,
          },
          {
            label: "Tipo Protocollo",
            key: "tipoProtocollo",
            sortable: true,
          },
          {
            label: "Numero Protocollo",
            key: "numeroProtocollo",
            sortable: true,
          },
          {
            label: "Utente",
            key: "createUser",
            sortable: true,
          },
        ],      
      };
    },
    mounted() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      if (sessionStorage["filtro"]) {
        me.filtro = JSON.parse(sessionStorage["filtro"]);
      }
    },
    methods: {
      loadData() {
        let me = this;
        let link = process.env.VUE_APP_PATH_API + me.pathResource;
        me.filtro.page = me.currentPage;
        me.filtro.forPage = me.perPage;
        axios
          .get(link, { params: me.filtro })
          .then((response) => {
            me.items = [];
            me.items = response.data.data.list;
            me.rows = response.data.data.recordsNumber;
            me.listKey++;
  //          me.changeGestore();
            this.$emit("afterLoadData");
          })
          .catch(() => {
            this.$emit("afterLoadData");
          });
      },
      onSubmit() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        sessionStorage["filtro"] = JSON.stringify(me.filtro);
        me.loadData();
      },
      onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
  },
};
  </script>
  
  <style></style>
  