<template>
    <sa-page-layout ref="pgaView" :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :showmolalloading="showmolalloading">
        <template slot="table-body">
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{getLabelTraduora('bancaEmatica.lblNewOperatorType')}}</h5>
                    <b-row>
                        <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">ID Tipologia</span>
                            <b-form-input v-model="jsonData.id" :placeholder="getLabelTraduora('bancaEmatica.lblOperatorTypeId')"></b-form-input>
                        </b-col> -->
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{getLabelTraduora('bancaEmatica.lblOperatorTypeName')}}</span>
                            <b-form-input v-model="jsonData.descrizione"  :placeholder="getLabelTraduora('bancaEmatica.lblOperatorTypeName')"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                </div>
        </template>
        <template slot="table-footer">
           
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins:[UtilityMixin],
    components: {  SaPageLayout },
    data() {
        return {
            pathResource: "/bancaematicaoperatoretipi",
            linkback: null,
            showmolalloading: false,
            id: "-1",
            filtro:{},
            jsonData: {  },
        };
    },
    computed: {
        
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/bancaematicaoperatoretipi";
        } else {
            me.linkback = "/bancaematicaoperatoretipi/view/" + me.id;
        }
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                //me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
    },
};
</script>
