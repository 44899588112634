<template>
  <div class="sa-container">
    <sa-sidebar v-bind:externalmenu="menu" v-bind:nominativoUtente="nominativoUtente" v-bind:ruolo="ruolo" :hidden="hiddenMenuLeft"></sa-sidebar>
    <div :class="mainClass">
      <sa-nav-bar :pageTitle="pageTitle" :userName="nominativoUtente" :idUtente="user.id" :hidden="hiddenNavBar" />
      <div class="sa-main-container">
        <router-view :utente="user" />
      </div>
    </div>
    <div id="sa-sidebar-overlay" v-sa-aside-toggle></div>
    <sa-modal-loading ref="sa-modal-loading" />
  </div>
</template>

<script>
import Vue from "vue";
import { SaSidebar, SaNavBar } from "../../../template/layout";
import axios from "axios";
import { SaModalLoading } from "../../utility";
export default {
  name: "Main",
  components: { SaSidebar, SaNavBar, SaModalLoading },
  data() {
    return {
      hiddenMenuLeft: false,
      hiddenNavBar: false,
      mainClass: "sa-main",
      avatar: "",
      pageTitle: "",
      nominativoUtente: "",
      ruolo: "Super Administrator",
      user: { idUtente: null },
      menu: [],
    };
  },
  created() {
    console.log("Eccomi");
    let viewType = this.$route.query.view;
    if (viewType === "module") {
      this.hiddenMenuLeft = true;
      this.hiddenNavBar = true;
      this.mainClass = "sa-main sa-main-module";
    }
  },
  mounted() {
    let me = this;
    this.loadUserData();
    // this.updateSidebar();
    this.interval = setInterval(() => me.verificaSessione(), 180000);
  },
  methods: {
    verificaSessione() {
      let link = process.env.VUE_APP_PATH_API + "/sessioncontrol";
      axios
        .get(link)
        .then(() => {})
        .catch(() => {
          clearInterval(this.interval);
          this.$router.push("/login");
        });
    },
    loadUserData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/users/profile";
      me.$refs["sa-modal-loading"].show();
      axios
        .get(link)
        .then((response) => {
          let utente = response.data.data;
          me.user = utente;
          Vue.prototype.user = utente;
          me.nominativoUtente = utente.lastname + " " + utente.firstname;
          me.ruolo = utente.profilo;
          me.menu = response.data.data.gruppo.menuGruppo;
          if (utente.gruppo.menuGruppo.length === 1) {
            if (utente.gruppo.menuGruppo[0].routeId === "homepaziente") {
              me.$router.replace("paziente/homepaziente").catch((err) => {
                err;
              });
            } else {
              me.$router.replace(utente.gruppo.menuGruppo[0].viewId).catch((err) => {
                err;
              });
            }
          } else if (utente.gruppo.menuGruppo.length > 0) {
            let present = false;
            utente.gruppo.menuGruppo.forEach((element, index) => {
              if (this.$router.currentRoute.path.includes(element.viewId)) {
                present = true;
              } else {
                if (utente.gruppo.menuGruppo[index].children !== null) {
                  utente.gruppo.menuGruppo[index].children.forEach((elementChild) => {
                    if (this.$router.currentRoute.path.includes(elementChild.viewId)) {
                      present = true;
                    }
                  });
                }
              }
            });
            if (!present) {
              me.$router.replace(utente.gruppo.menuGruppo[0].viewId).catch((err) => {
                err;
              });
            }
          }
          me.$refs["sa-modal-loading"].close();
        })
        .catch((e) => {
          console.log(e);
          //   this.errors.push(e);
        });
    },
    getIcon(item) {
      let returnVale = "bi bi-" + item.iconCls;
      return returnVale;
    },
    onMenuDownClick(event) {
      event.currentTarget.classList.toggle("sa-show-sub-menu");
    },
  },
};
</script>

<style></style>
