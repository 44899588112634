<template>
  <div class="sa-list-component">
    <h3 class="sa-event-title">{{ protocolloModulo.modulo }} - {{ protocolloModulo.documento }} - {{ protocolloModulo.protocollo }}</h3>
    <div class="sa-list-component-filter">
      <!-- <label class="sa-label-data">{{ protocolloModulo.modulo }} - {{ protocolloModulo.documento }} - {{ protocolloModulo.protocollo }}</label> -->
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Tipo:</label>
            <!-- <b-form-input v-model="filtro.tipo"></b-form-input> -->
            <b-form-select v-model="filtro.tipo" :options="tipoOptions" value-field="value" text-field="text"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Gruppo:</label>
            <b-form-input v-model="filtro.gruppo"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Codice Ufficio:</label>
            <b-form-input v-model="filtro.codiceUfficio"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Username:</label>
            <b-form-input v-model="filtro.username"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Email:</label>
            <b-form-input v-model="filtro.destinatarioMail"></b-form-input>
          </b-col>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="sa-list-component-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template v-slot:cell(tipo)="{ item }">
            <router-link class="sa-edit-link" :to="'/protocolloutenti/view/' + item.id">{{ item.tipo }}</router-link>
          </template>
          <template v-slot:cell(ufficio)="{ item }">
            <span>{{ item.descrizioneUfficio }} </span>
            <span v-if="item.descrizioneUfficio !== null && item.descrizioneUfficio !== ''">({{ item.codiceUfficio }})</span>
          </template>
          <template v-slot:cell(destinatario)="{ item }">
            <span>{{ item.destinatario }} </span>
            <span class="sa-label-data-secondary">
              <span v-if="item.destinatario !== null && item.destinatario !== ''">{{ item.destinatarioMail }}</span>
            </span>
          </template>
          <template #cell(action)="row">
            <b-button size="sm" variant="outline-danger no-text" @click="onDelete(row.item)">
              <b-icon icon="trash"></b-icon>
            </b-button>
            &nbsp;
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      pathResource: "/protocolmoduledestinations",
      filtro: { tipo: "", gruppo: "", codiceUfficio: "", username: "", destinatarioMail: "", idModule: "" },
      items: [],
      protocolloModulo: {},
      tipoOptions: [
        { text: "-Selezionare il Tipo-", value: "" },
        { text: "ENTRATA", value: "E" },
        { text: "INTERNO", value: "I" },
        { text: "USCITA", value: "U" },
        { text: "DESTINATARIO ESTERNO", value: "DESTINATARIESTERNI" },
      ],
      listKey: 0,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo",
          key: "tipo",
          sortable: true,
          thStyle: "width: 15rem",
        },
        {
          label: "Ufficio",
          key: "ufficio",
          sortable: true,
        },
        {
          label: "Destinatario",
          key: "destinatario",
          sortable: true,
          thStyle: "width: 15rem",
        },
        {
          label: "Username",
          key: "username",
          sortable: true,
        },
        {
          label: "Gruppo",
          key: "gruppo",
          sortable: true,
        },
        {
          label: "Oggetto",
          key: "oggetto",
          sortable: true,
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 7rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    if (sessionStorage["protocolloModulo"]) {
      me.protocolloModulo = JSON.parse(sessionStorage["protocolloModulo"]);
    }
    me.filtro.idModule = me.protocolloModulo.id;
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.listKey++;
          //          me.changeGestore();
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onDelete(item) {
      console.log(item);
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler Eliminare? ")
        .then((value) => {
          if (value) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.delete(link + item.id).then(() => {
              me.loadData();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
