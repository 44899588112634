<template>
  <sa-page-layout ref="pgaView" :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <h5 class="sa-text-left">{{getLabelTraduora("bankhematic.lblTitleScorte")}}</h5>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblRequestPacksNumber")}}</span>
              <b-form-input v-model="jsonData.numeroSaccheRichieste" :placeholder="getLabelTraduora('bancaEmatica.lblRequestPacksNumber')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblRequestDate")}}</span>
              <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataRichiesta" type="datetime" :placeholder="getLabelTraduora('bancaEmatica.lblRequestDate')"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblTypeOfBlood")}}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipologia" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaOption" :value="null"></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblPackType")}}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipologiaSacca" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaSaccaOption" :value="null" @input="onTipologiaSaccaInput()"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblBloodGroup")}}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null" :disabled="disabilitato"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblRhFactor")}}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null" :disabled="disabilitato"></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblMedicRegistrationNumber")}}</span>
              <b-input-group class="mb-3">
                <b-form-input v-model="jsonData.matricolaMedico" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblMedicRegistrationNumber')"></b-form-input>
                <b-button ref="refBtnMedico" id="btnMatricolaMedico" size="sm" variant="" v-b-modal.ricercaMatricolaMedico @click="onClickMatricola('MEDICO')">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("teleconsulto.lblDoctorFirstname")}}</span>
              <b-form-input v-model="jsonData.nomeMedico" readOnly :placeholder="getLabelTraduora('teleconsulto.lblDoctorFirstname')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("teleconsulto.lblDoctorLastname")}}</span>
              <b-form-input v-model="jsonData.cognomeMedico" readOnly :placeholder="getLabelTraduora('teleconsulto.lblDoctorLastname')"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblOperatorRegistrationNumber")}}</span>
              <b-input-group class="mb-3">
                <b-form-input v-model="jsonData.matricolaOperatore" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblOperatorRegistrationNumber')"></b-form-input>
                <b-button ref="refBtnOperatore" id="btnMatricolaOperatore" size="sm" variant="" v-b-modal.ricercaMatricolaOperatore @click="onClickMatricola(null)">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblOperatorName")}}</span>
              <b-form-input v-model="jsonData.nomeOperatore" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblOperatorName')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblOperatorSurname")}}</span>
              <b-form-input v-model="jsonData.cognomeOperatore" readOnly :placeholder="getLabelTraduora('bancaEmatica.lblOperatorSurname')"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblHB")}}</span>
              <b-form-input v-model="jsonData.hb" placeholder="HB"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblINR")}}</span>
              <b-form-input v-model="jsonData.inr" placeholder="INR"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblPT")}}</span>
              <b-form-input v-model="jsonData.pt" placeholder="PT"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblPTT")}}</span>
              <b-form-input v-model="jsonData.pit" placeholder="PTT"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblAPTT")}}</span>
              <b-form-input v-model="jsonData.aptt" placeholder="APTT"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-selection">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblRequestReason")}}</span>
              <b-form-textarea id="textarea-no-resize" v-model="jsonData.motivoRichiesta" :placeholder="getLabelTraduora('bancaEmatica.lblRequestReason')" rows="3" no-resize></b-form-textarea>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-modal id="ricercaMatricolaMedico" :title="getLabelTraduora('bancaEmatica.lblSearchMedicRegistrationNumber')" @hidden="onCloseModalMatricola()" size="xl" @ok="onOkMatricolaMedico">
        <b-row>
          <b-col cols="6">
            <span class="sa-form-label">{{getLabelTraduora("patients.lblName")}}</span>
            <b-form-input v-model="filtroModal.nome" :placeholder="getLabelTraduora('patients.lblName')" @input="filtroMedico(filtroModal.nome)"></b-form-input>
          </b-col>
          <b-col cols="6">
            <span class="sa-form-label">{{getLabelTraduora("patients.lblLastname")}}</span>
            <b-form-input v-model="filtroModal.cognome" :placeholder="getLabelTraduora('patients.lblLastname')" @input="filtroMedico(filtroModal.cognome)"></b-form-input>
          </b-col>
        </b-row>
        <br />
        <b-table
          id="tabellaRichieste"
          :items="tabellaMatricole"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filtroModal"
          :fields="matricoleFields"
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <template>
          <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
            <b-col cols="6">
              <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
            </b-col>
            <b-col cols="6">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <b-modal id="ricercaMatricolaOperatore" :title="getLabelTraduora('bancaEmatica.lblSearchOperatorRegistrationNumber')" size="xl" @hidden="onCloseModalMatricola()" @ok="onOkMatricola">
        <!-- <b-button size="sm" variant="" @click="onClickMatricola"></b-button> -->
        <!-- <b-input-group class="mb-3">
                    <b-form-input v-model="numeroRichiesta" placeholder="Richieste"></b-form-input>
                   
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group> -->
        <b-row>
          <b-col cols="4">
            <span class="sa-form-label">{{getLabelTraduora("patients.lblName")}}</span>
            <b-form-input v-model="filtroModal.nome" :placeholder="getLabelTraduora('patients.lblName')" @input="filtroMedico(filtroModal.nome)"></b-form-input>
          </b-col>
          <b-col cols="4">
            <span class="sa-form-label">{{getLabelTraduora("patients.lblLastname")}}</span>
            <b-form-input v-model="filtroModal.cognome" :placeholder="getLabelTraduora('patients.lblLastname')" @input="filtroMedico(filtroModal.cognome)"></b-form-input>
          </b-col>
          <b-col cols="4">
            <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblTypeOfBlood")}}</span>
            <b-form-select
              id="inline-form-custom-select-pref"
              v-model="filtroModal.tipo"
              class="mb-2 mr-sm-2 mb-sm-0"
              :options="tipoOption"
              :value="null"
              value-field="descrizione"
              text-field="descrizione"
              @input="onClickMatricola(filtroModal.tipo)"
            >
              <template #first>
                <b-form-select-option disabled :value="null">{{getLabelTraduora("global.lblSelectValue")}}</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <br />
        <b-table
          id="tabellaMatricole"
          :items="tabellaMatricole"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filtroModal"
          :fields="matricoleFields"
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <template>
          <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
            <b-col cols="6">
              <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
            </b-col>
            <b-col cols="6">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </template>

    <template slot="footer">
      <b-modal id="ricercaRichiesta" :title="getLabelTraduora('bancaEmatica.lblSearchRequest')" @show="onShow" @ok="onOk">
        <b-input-group class="mb-3">
          <b-form-input v-model="numeroRichiesta" :placeholder="getLabelTraduora('bancaEmatica.lblRequestNumber')"></b-form-input>
          <b-button size="sm" variant="" v-b-modal.ricercaRichiesta @click="onClick">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group>
        <b-table id="tabellaRichieste" :items="tabellaRichieste" :fields="richiesteFields" selectable select-mode="single" @row-selected="onRowSelected"> </b-table>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins:[UtilityMixin],
  components: { DatePicker, SaPageLayout },
  data() {
    return {
      pathResource: "/bancheematichetrasfusionirichiestescorte",
      pathRESTRequest: "/bancaematica",
      pathRESTMatricoleOperatori: "/bancaematicaoperatori",
      linkstato: "/bancheematichetrasfusionirichiestescortestatitipi",
      linkgrupposanguigno: "/bancheematichegruppisanguigni",
      linktipologiesacche: "/bancaematicatipologiesacche",
      linkfattorerh: "/bancaematicafattorirh",
      linktipologie: "/bancheematichetipiemoderivatitipologie",
      pathResourceAggiornaStato: "/aggiornaStato",
      linkedit: null,
      linkback: null,
      showModalLoading: false,
      id: "-1",
      tabellaMatricole: [],
      matricoleFields: [
        {
          label: "",
          key: "selected",
          sortable: true,
          thStyle: "width: 2rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblOperatorRegistrationNumber"),
          key: "matricola",
          sortable: true,
          thStyle: "width: 7rem",
        },

        {
          label: this.getLabelTraduora("patients.lblLastname"),
          key: "cognome",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: this.getLabelTraduora("patients.lblName"),
          key: "nome",
          sortable: true,
          thStyle: "width: 7rem",
        },
      ],
      filtroModal: { nome: "", cognome: "", tipo: null },
      disabilitato: false,
      profili: [],
      currentPage: 1,
      perPage: 10,
      statoOption: [{ value: null, text: "--Seleziona Valore--" }],
      gruppoSanguignoOption: [{ value: null, text: "--Seleziona Valore--" }],
      fattoreRhOption: [{ value: null, text: "--Seleziona Valore--" }],
      tipologiaOption: [{ value: null, text: "--Seleziona Valore--" }],
      tipologiaSaccaOption: [{ value: null, text: "--Seleziona Valore--" }],
      tipoOption: [{ value: "", descrizione: "TUTTI" }],
      tabellaRichieste: [],
      rigaSelezionata: null,
      numeroRichiesta: null,
      pathRestRichiesteScorte: "/bancheematichetrasfusionirichiestescorte",
      pathRestOperatori: "/bancaematicaoperatoretipi",
      jsonData: {
        statoAttuale: null,
        gruppoSanguigno: null,
        fattoreRh: null,
        numeroRichiesta: null,
        tipologia: null,
        tipologiaSacca: null,
      },
      richiesteFields: [
        {
          label: this.getLabelTraduora("bancaEmatica.lblRequestNumber"),
          key: "numeroRichiesta",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblMedicRegistrationNumber"),
          key: "matricolaMedico",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblBloodGroup"),
          key: "gruppoSanguigno",
          sortable: true,
          thStyle: "width: 7rem",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.tabellaMatricole.length;
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/bancaematicarichiestascorte";
    } else {
      me.linkback = "/bancaematicarichiestascorte/view/" + me.id;
    }
    console.log(me.filtroModal.tipo);
    me.filtroModal.tipo = null;
    me.loadDefaultData();
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadData();
      me.loadFattoreRh();
      me.loadGruppoSanguigno();
      me.loadStato();
      me.loadTipologia();
      me.loadTipologiaSacca();
      me.loadTipoOperatore();
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        //me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    loadTipoOperatore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRestOperatori + "?forPage=100000&page=1";
      axios.get(link).then((response) => {
        me.tipoOption = response.data.data.list;
        me.filtroModal.tipo = me.tipoOption[1].descrizione;
      });
    },
    loadStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkstato;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.statoOption.push({
            value: element.valore,
            text: element.descrizione,
          });
        });
      });
    },
    loadGruppoSanguigno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    loadTipologia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linktipologie;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipologiaOption.push({
            value: element.tipologia,
            text: element.tipologia,
          });
        });
      });
    },
    loadTipologiaSacca() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linktipologiesacche;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipologiaSaccaOption.push({
            value: element.tipologiaSacca,
            text: element.tipologiaSacca,
          });
        });
      });
    },
    loadFattoreRh() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.fattoreRhOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    loadTipoOption() {},
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else {
        return "-";
      }
    },
    onShow() {
      let me = this;
      me.tabellaRichieste = [];
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rigaSelezionata = items[0];
      }
    },
    onOk() {
      let me = this;
      me.jsonData.numeroRichiesta = me.rigaSelezionata.numeroRichiesta;
      me.jsonData.hb = me.rigaSelezionata.hb;
      me.jsonData.inr = me.rigaSelezionata.inr;
      me.jsonData.pt = me.rigaSelezionata.pt;
      me.jsonData.pit = me.rigaSelezionata.pit;
      me.jsonData.aptt = me.rigaSelezionata.aptt;
      me.jsonData.gruppoSanguigno = me.rigaSelezionata.gruppoSanguigno;
      me.jsonData.codiceSacca = me.rigaSelezionata.codiceSacca;
      me.jsonData.fattoreRh = me.rigaSelezionata.fattoreRh;
    },
    onOkMatricola() {
      let me = this;
      me.jsonData.matricolaOperatore = me.rigaSelezionata.matricola;
      me.jsonData.nomeOperatore = me.rigaSelezionata.nome;
      me.jsonData.cognomeOperatore = me.rigaSelezionata.cognome;
    },
    onOkMatricolaMedico() {
      let me = this;
      me.jsonData.matricolaMedico = me.rigaSelezionata.matricola;
      me.jsonData.nomeMedico = me.rigaSelezionata.nome;
      me.jsonData.cognomeMedico = me.rigaSelezionata.cognome;
    },
    onCloseModalMatricola() {
      let me = this;
      me.tabellaMatricole = [];
      me.filtroModal.nome = "";
      me.filtroModal.cognome = "";
      me.filtroModal.tipo = null;
    },
    onClick() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRestRichiesteScorte + "/";
      axios
        .get(link, { params: { numeroRichiesta: me.numeroRichiesta } })
        .then((response) => {
          me.tabellaRichieste = response.data.data;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          me.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onClickMatricola(tipo) {
      let me = this;
      me.showModalLoading = true;
      if (tipo !== "" && tipo != null) {
        me.filtroModal.tipo = tipo;
      } else {
        me.filtroModal.tipo = me.tipoOption[1].descrizione;
      }
      let link = process.env.VUE_APP_PATH_API + me.pathRESTMatricoleOperatori + "?forPage=10&page=1&tipo=" + me.filtroModal.tipo + "&" + "nome=" + me.filtroModal.nome + "&cognome=" + me.filtroModal.cognome;
      axios
        .get(link)
        .then((response) => {
          me.tabellaMatricole = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          me.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
      me.showModalLoading = false;
    },
    filtroMedico(value) {
      let me = this;
      if (value) {
        me.tabellaMatricole = me.tabellaMatricole.filter((element) => {
          return element.nome.toUpperCase().includes(value.toUpperCase()) || element.cognome.toUpperCase().includes(value.toUpperCase());
        });
      } else {
        me.onClickMatricola("MEDICO");
      }
    },
    onTipologiaSaccaInput() {
      let me = this;
      me.disabilitato = false;
      if (me.jsonData.tipologiaSacca === "Piastrine") {
        me.disabilitato = true;
      }
    },
    onSave() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      let jsonData = JSON.stringify(me.data);

      me.showModalLoading = true;
      if (me.id == "-1") {
        axios
          .post(link, jsonData)
          .then((response) => {
            me.data = response.data.data;
            me.showModalLoading = false;
            me.$router.replace(me.routeLink).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        axios
          .put(link + "/" + me.id, jsonData)
          .then((response) => {
            me.gruppo = response.data.data;
            me.$refs["sa-modal-loading"].close();
            me.$router.replace(me.routeLink).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.$refs["sa-modal-loading"].close();
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
  },
};
</script>
