<template>
  <div>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Modulo:</label>
          <span class="sa-data">{{ modulo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Protocollo:</label>
          <span class="sa-data">{{ tipo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Gruppo:</label>
          <span class="sa-data">{{ jsonData.gruppo }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="isEsterno" class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Persona:</label>
          <span class="sa-data">{{ tipoPersona }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nominativo:</label>
          <span class="sa-data">{{ jsonData.destinatario }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">E-Mail Destinatario:</label>
          <span class="sa-data">{{ jsonData.destinatarioMail }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ufficio:</label>
          <span class="sa-data">{{ jsonData.descrizioneUfficio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Utente:</label>
          <span class="sa-data">{{ jsonData.username }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Oggetto:</label>
          <span class="sa-data">{{ jsonData.oggetto }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      id: "-1",
      pathResource: "/protocolmoduledestinations",
      pathResourceUffici: "/protocolgedoc/uffici",
      pathResourceUtenti: "/protocolgedoc/utentibyufficio",
      pathResourceModule: "/protocolmodules",
      pathResourceMalattieInfettiveUosd: "/malattieinfettiveuosd",
      modulo: "",
      tipo: "",
      tipoPersona: "",
      isMalattieInfettiveSegnalazione: false,
      isEsterno: false,
      tipoOptions: [
        { text: "-Selezionare il Tipo-", value: "" },
        { text: "ENTRATA", value: "E" },
        { text: "INTERNO", value: "I" },
        { text: "USCITA", value: "U" },
        { text: "DESTINATARIO ESTERNO", value: "DESTINATARIESTERNI" },
      ],
      tipoPersonaOptions: [
        { text: "-Selezionare il Tipo-", value: "" },
        { text: "FISICA", value: "F" },
        { text: "GIURIDICA", value: "G" },
      ],
      malattieInfettiveUosdOptions: [],
      ufficiOptions: [],
      listaUffici: [],
      utentiOptions: [{ text: "-Selezionare Utente-", value: "" }],
      listaUtenti: [],
      moduleOptions: [],
      jsonData: {
        tipo: "",
        codiceUfficio: "",
        oggetto: "",
        username: "",
        descrizioneUfficio: "",
        idModule: "",
        tipoPersona: "",
        destinatario: "",
        destinatarioMail: "",
        gruppo: "",
      },
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.loadModule();
    me.loadMalattieInfettiveUosd();
    me.loadUffici();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.tipo": function (value) {
      let me = this;
      if (value === "DESTINATARIESTERNI") {
        me.isEsterno = true;
      } else {
        me.isEsterno = false;
      }
    },
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadData() {
      let me = this;
      this.$emit("beforeLoadData");
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios.get(link + me.id).then((response) => {
        me.jsonData = response.data.data;
        me.setLabel();
        this.$emit("afterLoadData");
      });
    },
    loadMalattieInfettiveUosd() {
      let me = this;
      me.malattieInfettiveUosdOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattieInfettiveUosd + "?page=1&forPage=100";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.malattieInfettiveUosdOptions.push({ text: element.descrizione, value: element.descrizione });
        });
        me.malattieInfettiveUosdOptions.unshift({ text: "-Seleziona Gruppo-", value: "" });
      });
    },
    loadUffici() {
      let me = this;
      me.listaUffici = [];
      me.ufficiOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.pathResourceUffici;
      axios.get(link).then((response) => {
        me.listaUffici = response.data.data.lista_uffici;
        me.ufficiOptions = response.data.data.lista_uffici;
        me.ufficiOptions.unshift({ descrizione: "-Seleziona Ufficio-", id: "" });
      });
    },
    onSelectUfficio() {
      let me = this;
      me.listaUffici.forEach((element) => {
        if (element.id === me.jsonData.codiceUfficio) {
          me.jsonData.descrizioneUfficio = element.descrizione;
        }
      });
      me.loadListaUtenti();
    },
    loadListaUtenti() {
      let me = this;
      me.listaUtenti = [];
      me.utentiOptions = [{ text: "-Selezionare Utente-", value: "" }];
      let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti + "?idUfficio=" + me.jsonData.codiceUfficio;
      axios.get(link).then((response) => {
        me.listaUtenti = response.data.data.lista_utenti;
        response.data.data.lista_utenti.forEach((element) => {
          me.utentiOptions.push({ text: element.username, value: element.username });
        });
      });
    },
    onSelectUtente() {
      let me = this;
      me.listaUtenti.forEach((element) => {
        if (element.usename === me.jsonData.username) {
          me.jsonData.destinatario = element.cognome + " " + element.nome;
        }
      });
    },
    loadModule() {
      let me = this;
      me.moduleOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.pathResourceModule;
      axios.get(link).then((response) => {
        me.listaUtenti = response.data.data.lista_utenti;
        response.data.data.list.forEach((element) => {
          me.moduleOptions.push({ text: element.modulo + " (" + element.documento + ")", value: element.id });
        });
        me.moduleOptions.unshift({ text: "-Seleziona Modulo-", value: "" });
      });
    },
    setLabel() {
      let me = this;
      if (me.jsonData.idModule !== null && me.jsonData.idModule !== "") {
        me.moduleOptions.forEach((element) => {
          if (element.value === me.jsonData.idModule) {
            me.modulo = element.text;
          }
        });
      }
      if (me.jsonData.tipo !== null && me.jsonData.tipo !== "") {
        me.tipoOptions.forEach((element) => {
          if (element.value === me.jsonData.tipo) {
            me.tipo = element.text;
          }
        });
      }
      if (me.jsonData.tipoPersona !== null && me.jsonData.tipoPersona !== "") {
        me.tipoPersonaOptions.forEach((element) => {
          if (element.value === me.jsonData.tipoPersona) {
            me.tipoPersona = element.text;
          }
        });
      }
    },
  },
};
</script>

<style></style>
