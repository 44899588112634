<template>
  <div class="sa-list-component">
    <div class="sa-list-component-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Documento:</label>
            <b-form-input v-model="filtro.codiceTipoDocumento"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Subreport:</label>
            <b-form-input v-model="filtro.subreport"></b-form-input>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="sa-list-component-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template v-slot:cell(codiceTipoDocumento)="{ item }">
            <router-link class="sa-edit-link" :to="'/printreport/view/' + item.id">{{ item.codiceTipoDocumento }}</router-link>
          </template>
          <template #cell(action)="row">
            <b-button size="sm" variant="outline-success no-text" @click="onOpenModal(row.item)">
              <i class="fas fa-file-upload"></i>
            </b-button>
            <b-button size="sm" variant="outline-danger no-text" @click="onDelete(row.item)">
              <b-icon icon="trash"></b-icon>
            </b-button>
            &nbsp;
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal size="xl" ref="mdlAllegato" id="mdlAllegato" @ok="onOk">
      <b-form-file v-model="file1" :state="Boolean(file1)" :placeholder="this.$i18n.t('global.dragDrop')" ref="file-input" class="custom-input" type="file" accept=".jasper"></b-form-file>
      <div class="mt-3">{{ this.$i18n.t("patient.attachments.fileSelected") }} {{ file1 ? file1.name : "" }}</div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  watch: {
    file1: function (e) {
      let me = this;
      // console.log(e);
      if (e !== null) {
        const selectedFile = e;
        me.jsonData.name = e.name;
        this.createBase64(selectedFile);
      }
    },
  },
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      file1: null,
      pdf: null,
      pathResource: "/printreporttipi",
      filtro: { codiceTipoDocumento: "", subreport: "", idReportTipo: "" },
      items: [],
      jsonData: { name: "", base64: "" },
      recordSelezionato: {},
      listKey: 0,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Codice Documento",
          key: "codiceTipoDocumento",
          sortable: true,
          thStyle: "width: 15rem",
        },
        {
          label: "Nome Subreport",
          key: "subreport",
          sortable: true,
        },
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: true,
          thStyle: "width: 15rem",
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 7rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.listKey++;
          //          me.changeGestore();
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onDelete(item) {
      console.log(item);
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler Eliminare? ")
        .then((value) => {
          if (value) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.delete(link + item.id).then(() => {
              me.loadData();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onOpenModal(value) {
      let me = this;
      me.recordSelezionato = {};
      me.recordSelezionato = value;
      this.$refs["mdlAllegato"].show();
    },
    onOk() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/printreporttipi/upload";
      me.jsonData.idReportTipo = me.recordSelezionato.id;
      axios
        .post(link, me.jsonData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    createBase64(fileObject) {
      let me = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        me.pdf = e.target.result;
        me.jsonData.base64 = me.pdf;
      };
      reader.readAsDataURL(fileObject);
    },
  },
};
</script>

<style></style>
