<template>
  <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <div style="float: right">
        <print-component :reportName="reportName" :data="items"></print-component>
      </div>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" fixed stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(actions)="{ item }">
            <b-button size="sm" variant="outline-secondary width-sm no-text" @click="onClick(item.id)">
              <b-icon icon="search"></b-icon>
            </b-button>
          </template>
          <template v-slot:cell(medico)="{ item }"> {{ item.matricolaMedico }}&nbsp;{{ item.nomeMedico }}&nbsp;{{ item.cognomeMedico }} </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal id="mdlDettaglioRichiesta" refs="mdlDettaglioRichiesta" size="xl" title="Dettaglio Richiesta" @ok="onOk">
        <banca-ematica-richieste-reparto-view-component :id="idDettaglio"></banca-ematica-richieste-reparto-view-component>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import BancaEmaticaRichiesteRepartoViewComponent from "../components/BancaEmaticaRichiesteRepartoViewComponent.vue";
// import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  components: { PrintComponent, SaPageLayout, BancaEmaticaRichiesteRepartoViewComponent },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      pathResource: "/bancaematicatrasfusionirichiestereparti",
      linkback: "/bancaematicatotali",
      reportName: "ReportListaRichiestaReparto",
      showModalLoading: false,
      perPage: 10,
      currentPage: 1,
      disabilitatoRichiesta: true,
      disabilitatoDestinatario: true,
      note: "",
      filter: null,
      id: "-1",
      idDettaglio: null,
      filtroTotali: {},
      filterOn: [],
      fields: [
        {
          label: this.getLabelTraduora("bancaEmatica.lblRequestNumber"),
          key: "numeroRichiesta",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: "N. Cartella",
          key: "cartellaClinica",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: "Reparto",
          key: "reparto",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: "Medico",
          key: "medico",
          thStyle: "width: 10rem",
          sortable: true,
        },
        {
          label: "Gr. Sang",
          key: "gruppoSanguigno",
          thStyle: "width: 6rem",
          sortable: true,
        },
        {
          label: "N. Sacche Rich.",
          key: "numeroSaccheRichieste",
          thStyle: "width: 5rem",
          // sortable: true,
        },
        {
          label: "Grad. Urg.",
          key: "gradoUrgenza",
          thStyle: "width: 5rem",
          sortable: true,
        },

        {
          label: this.getLabelTraduora("sequenziamento.lblstatoAttuale"),
          key: "statoAttuale",
          thStyle: "width: 6rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("sequenziamento.lblstatoAttualeDataOra"),
          key: "statoAttualeDataOra",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            }
          },
          thStyle: "width: 6rem",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 3rem",
        },
      ],
      items: [],
      data: { stato: null, note: "", idBancaEmatica: "", destinatario: null },
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.filtroTotali.reparti = this.$route.query.reparti;
    me.filtroTotali.medici = this.$route.query.medici;
    me.filtroTotali.dataDal = this.$route.query.dataDal;
    me.filtroTotali.dataAl = this.$route.query.dataAl;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?page=1&forPage=50";
      me.isBusy = true;
      // if (me.filtro.statoAttuale === null) {
      //   me.filtro.statoAttuale = "DISPONIBILE";
      // }
      axios
        .get(link, { params: me.filtroTotali })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          for (let element in me.items) {
            // console.log((me.items[element]["dataScadenza"] - Date.now()) / (1000 * 60 * 60 * 24));
            let differenza = ((me.items[element]["dataScadenza"] - Date.now()) / (1000 * 60 * 60 * 24)).toFixed(0);
            // console.log(differenza);
            me.items[element]["_cellVariants"] = {};
            if (differenza > 3) {
              me.items[element]["_cellVariants"].dataScadenza = "success";
            } else if (differenza == 3) {
              me.items[element]["_cellVariants"].dataScadenza = "warning";
            } else {
              me.items[element]["_cellVariants"].dataScadenza = "danger";
            }

            if (me.items[element]["statoAttuale"] === "DISPONIBILE") {
              me.items[element]["_cellVariants"].statoAttuale = "success";
            }
          }
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onCambioStato(item) {
      let me = this;
      me.$refs.tstModal.show();
      me.data.idBancaEmatica = item.id;
      me.data.note = item.note;
      // console.log(item);
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtroTotali"] = JSON.stringify(me.filtroTotali);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtroTotali = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtroTotali"] = JSON.stringify(me.filtroTotali.codiceSacca);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onOk() {
      let me = this;
      // let link = process.env.VUE_APP_PATH_API + me.pathResource + me.pathResourceAggiornaStato;
      // let jsonData = JSON.stringify(me.data);
      // axios.post(link, jsonData).then(() => {
      //   me.showModalLoading = false;
      //   me.loadData();
      // });
      me.idDettaglio = null;
    },
    onHiddenModal() {
      let me = this;
      me.data = { stato: null, note: "", idBancaEmatica: "", destinatario: null };
    },
    loadStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkstato;
      axios.get(link).then((response) => {
        // console.log(response.data.data);
        response.data.data.forEach((element) => {
          me.statoOption.push({ value: element.valore, text: element.valore });
        });
        me.filtroTotali.statoAttuale = "DISPONIBILE";
      });
    },
    loadDestinatario() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkdestinatario + "?page=1&forPage=100000000";
      me.destinatarioOption = [{ value: null, text: "-Destinatario-" }];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.destinatarioOption.push({ value: element.descrizioneReparto, text: element.descrizioneReparto });
        });
      });
    },
    loadGruppoSanguigno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    loadFattoreRh() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.fattoreRhOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else if (valore === "NEGATIVO") {
        return "-";
      } else {
        return "";
      }
    },
    onClick(value) {
      console.log(value);
      let me = this;
      me.idDettaglio = value;
      me.$bvModal.show("mdlDettaglioRichiesta");
    },
    onInputStatoSacca(value) {
      let me = this;
      me.disabilitatoDestinatario = true;
      me.disabilitatoRichiesta = true;
      if (value === "EROGATO") {
        me.disabilitatoDestinatario = false;
        me.loadDestinatario();
        me.items.forEach((element) => {
          if (element.id === me.data.idBancaEmatica) {
            // console.log(element);
            if (element.provenienzaRichiesta === "SCORTE") {
              me.disabilitatoRichiesta = false;
            }
          }
        });
      }
    },
  },
  watch: {
    "data.stato": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value !== "EROGATO") {
          me.disabilitatoDestinatario = true;
          me.disabilitatoRichiesta = true;
        }
      },
    },
  },
};
</script>
