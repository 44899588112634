<template>
    <sa-page-layout ref="pgaView" :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :showmolalloading="showmolalloading">
        <template slot="table-body">
                <div class="sa-form-section">
                    <h5 class="sa-text-left">{{getLabelTraduora('bancaEmatica.lblNewOperator')}}</h5>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{getLabelTraduora('bancaEmatica.lblOperatorRegistrationNumber')}}</span>
                            <b-form-input v-model="jsonData.matricola" :placeholder="getLabelTraduora('bancaEmatica.lblOperatorRegistrationNumber')"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblOperatorSurname")}}</span>
                            <b-form-input v-model="jsonData.cognome" 
                            ></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblOperatorName")}}</span>
                            <b-form-input v-model="jsonData.nome" 
                            ></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <span class="sa-form-label">{{getLabelTraduora("bancaEmatica.lblNewOperatorType")}}</span>
                            <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tipo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiOperatore" :value="null"></b-form-select>

                        </b-col>
                    </b-row>
                </div>
        </template>
        <template slot="table-footer">
           
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins:[UtilityMixin],
    components: {  SaPageLayout },
    data() {
        return {
            pathResource: "/bancaematicaoperatori",
            linkback: null,
            showmolalloading: false,
            id: "-1",
            filtro:{},
            tipiOperatore:[],
            jsonData: {  },
        };
    },
    computed: {
        
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/bancaematicaoperatori";
        } else {
            me.linkback = "/bancaematicaoperatori/view/" + me.id;
        }
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadData();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showmolalloading = false;
            } else {
                //me.showmolalloading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                });
            }

            let linkTipi = process.env.VUE_APP_PATH_API + "/bancaematicaoperatoretipi?forPage=25&page=1"
            axios.get(linkTipi).then((res)=>{
                let tipi = res.data.data.list;
                tipi.forEach((tipo)=>{
                    let obj =  tipo.descrizione;
                    me.tipiOperatore.push(obj)
                })
            })
        },
    },
};
</script>
