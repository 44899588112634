<template>
    <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-free-page">
                <div class="sa-free-page-header"></div>
                <div class="sa-free-page-body">
                    <div class="sa-free-page-body-left">
                        <b-card class="sa-card sa-header-nowrap" style="flex: unset" header="Tabella Principale" header-tag="header" footer-tag="footer" title="">
                            <div style="display: flex">
                                <b-form-select v-model="jsonData.mainTable" :options="tablesInfo" value-field="dbName" text-field="viewName" @change="onChangeMainTableSelection"></b-form-select>
                                <b-button size="sm" variant="outline-secondary outline-secondary-noborder" @click="onFielsdSelection()" class="mr-1 no-text">
                                    <i class="fas fa-cog"></i>
                                </b-button>
                            </div>
                        </b-card>
                        <b-card class="sa-card sa-header-nowrap" header="Filtri" header-tag="header" footer-tag="footer" title="">
                            <template #header>
                                <div style="display: flex">
                                    <span style="flex: 1">Filtri</span>
                                    <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickFiltersEdit()">
                                        <i class="fas fa-plus"></i>
                                    </b-button>
                                </div>
                            </template>
                            <div style="overflow: scroll; height: 100%">
                                <div v-for="(filter, index) in jsonData.queryFilters" :key="index">
                                    <div class="sa-data-analysis-filter">
                                        <span class="sa-data-analysis-filter-operator">{{ filter.filterOperation }}</span>
                                        <span class="sa-data-analysis-filter-table">{{ filter.tableName }}</span>
                                        <span class="sa-data-analysis-filter-field">{{ filter.fieldName }}</span>
                                        <span class="sa-data-analysis-filter-operator">{{ filter.operatorName }}</span>
                                        <span class="sa-data-analysis-filter-value">{{ filter.value }}</span>
                                        <span class="sa-data-analysis-filter-button-remove" @click="onRemoveFilter(index)">
                                            <i class="fas fa-times-circle"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                        <b-card class="sa-card sa-header-nowrap" header="Joins" header-tag="header" footer-tag="footer" title="">
                            <template #header>
                                <div style="display: flex">
                                    <span style="flex: 1">Joins</span>
                                    <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickJoinsEdit()">
                                        <i class="fas fa-plus"></i>
                                    </b-button>
                                </div>
                            </template>
                            <div v-for="(join, index) in jsonData.joins" :key="index">
                                <div class="sa-data-analysis-join">
                                    <div v-if="index !== jsonData.joins.length - 1" style="margin-bottom: 3px; display: flex">
                                        <span class="sa-data-analysis-join-type">{{ join.typeJoin }}</span>
                                    </div>
                                    <div v-else style="margin-bottom: 3px; display: flex">
                                        <span class="sa-data-analysis-join-type">{{ join.typeJoin }}</span>
                                        <span class="sa-data-analysis-filter-button-remove" @click="onRemoveJoin(index)">
                                            <i class="fas fa-times-circle"></i>
                                        </span>
                                    </div>
                                    <div style="margin-bottom: 3px">
                                        <span class="sa-data-analysis-join-table">{{ join.tableLeft }}</span>
                                        <span class="sa-data-analysis-join-field">{{ join.fieldLeft }}</span>
                                    </div>
                                    <div style="margin-bottom: 3px">
                                        <span class="sa-data-analysis-join-table">{{ join.tableRight }}</span>
                                        <span class="sa-data-analysis-join-field">{{ join.fieldRight }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                        <b-card class="sa-card sa-header-nowrap" header="Ordinamento" header-tag="header" footer-tag="footer" title="">
                            <template #header>
                                <div style="display: flex">
                                    <span style="flex: 1">Ordinamento</span>
                                    <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right">
                                        <i class="fas fa-plus"></i>
                                    </b-button>
                                </div>
                            </template>
                            <div v-for="(sort, index) in jsonData.sorting" :key="index">
                                <div class="sa-data-analysis-filter">
                                    <span class="sa-data-analysis-filter-table">{{ sort.table }}</span>
                                    <span class="sa-data-analysis-filter-field">{{ sort.field }}</span>
                                    <span class="sa-data-analysis-filter-operator">{{ sort.order }}</span>
                                    <span class="sa-data-analysis-filter-button-remove" @click="onRemoveFilter(index)">
                                        <i class="fas fa-times-circle"></i>
                                    </span>
                                </div>
                            </div>
                        </b-card>
                        <b-card class="sa-card sa-header-nowrap" style="flex: unset" header="Paginazione" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Limit:</label>
                                    <b-form-select v-model="jsonData.queryLimit" :options="limitOptions" @change="onChangeLimit"></b-form-select>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Offset:</label>
                                    <b-form-input v-model="jsonData.queryOffset" type="number"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-card>
                    </div>
                    <div class="sa-free-page-body-center">
                        <b-card style="flex: unset" class="sa-card sa-header-nowrap" header="Query" header-tag="header" footer-tag="footer" title="">
                            <template #header>
                                <div style="display: flex">
                                    <span style="flex: 1">Query</span>
                                    <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickEseguiQuery()">
                                        <i class="bi bi-play-fill"></i>
                                        Esegui
                                    </b-button>
                                </div>
                            </template>
                            <code>{{ jsonData.sqlQuery }}</code>
                        </b-card>
                        <b-card class="sa-card sa-header-nowrap" header="Risultato" header-tag="header" footer-tag="footer" title="">
                            <template #header>
                                <div style="display: flex">
                                    <span style="flex: 1">Risultato</span>
                                    <!-- <b-button size="sm" variant="outline-secondary outline-secondary-noborder pull-right" @click="onClickEseguiQuery()">
                                        <i class="bi bi-play-fill"></i>
                                        Esegui
                                    </b-button> -->
                                </div>
                            </template>
                            <div style="overflow: scroll; height: 100%">
                                <div class="b-table-sticky-header">
                                    <b-table sticky-header ref="tblResultQuery" stacked="lg" striped hover :items="resultQueryitems" head-variant="light" class="sa-b-table"></b-table>
                                </div>
                            </div>
                        </b-card>
                    </div>
                    <div class="sa-free-page-body-right"></div>
                </div>
                <div class="sa-free-page-footer"></div>

                <b-modal scrollable ref="mdlFieldsSelection" id="mdlFieldsSelection" title="Fields" @ok="onMdlFieldsSelectionOk" @show="onMdlFiledsSelectionShow">
                    <div v-for="(tableInfo, index) in tablesInfoSelected" :key="index" class="sa-custom-collaps">
                        <!-- <span class="sa-custom-collaps-span-title">{{ jsonData.fields }}</span> -->

                        <span class="sa-custom-collaps-toggle-button" v-b-toggle="'accordion-' + index">
                            <i class="bi bi-chevron-right arrow"></i>
                            {{ tableInfo.viewName }} ({{ tableInfo.dbName }})
                        </span>
                        <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                            <div v-for="(field, fieldIndex) in tableInfo.tableFields" :key="fieldIndex" :class="getAlternateClsaa(fieldIndex)">
                                <b-row>
                                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <span class="sa-custom-collaps-span-title">
                                            <b-row>
                                                <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <b-form-checkbox switch v-model="jsonData.fields" class="mb-3" :value="field" text="name" disabled-field="notEnabled">
                                                        {{ field.fieldName }}
                                                        <span class="sa-custom-collaps-span-subtitle">{{ field.fieldType }}</span>
                                                    </b-form-checkbox>
                                                </b-col>
                                                <b-col xs="6" sm="6" md="12" lg="12" xl="6">
                                                    <b-form-input v-model="field.alias" placeholder="Alias"></b-form-input>
                                                </b-col>
                                            </b-row>
                                        </span>
                                    </b-col>
                                </b-row>
                                <hr />
                            </div>
                        </b-collapse>
                    </div>
                </b-modal>
                <b-modal ref="mdlJoinEdit" id="mdlJoinEdit" size="lg" title="Joins" @ok="onMdlJoinsSelectionOk">
                    <data-analysis-modal-join-component ref="cmpJoinComponent" :mainTable="jsonData.mainTable" :tablesOptions="tablesInfo"></data-analysis-modal-join-component>
                </b-modal>
                <b-modal ref="mdlFiltersEdit" id="mdlFiltersEdit" size="lg" title="Filtri" @ok="onMdlFiltersSelectionOk">
                    <data-analysis-modal-filter-component ref="cmpFilterComponent" :queryFilters="jsonData.queryFilters" :tablesOptions="tablesInfo"></data-analysis-modal-filter-component>
                </b-modal>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DataAnalysisModalJoinComponent from "../components/DataAnalysisModalJoinComponent.vue";
import DataAnalysisModalFilterComponent from "../components/DataAnalysisModalFilterComponent.vue";
export default {
    components: { SaPageLayout, DataAnalysisModalJoinComponent, DataAnalysisModalFilterComponent },
    data() {
        return {
            pathResourcesTables: "/dataanalysistables",
            pathResourceEseguiQuery: "/dataanalysisdatabaseinfo/eseguiquery",
            linkback: "/dataanalysisquery",
            showModalLoading: false,
            pathResource: "/dataanalysisquery",
            pathResourceTableInfo: "/dataanalysisdatabaseinfo/tableinfo",
            resultQueryitems: [],
            // jsonData: { tabellaPrincipale: "gesan_pazienti_misurazioni", query: "", fields: [], joins: [{ type: "LEFT JOIN", tableLeft: "prova", fieldLeft: "id", tableRight: "gea_measuraments", fieldRight: "id" }], limit: "10" },
            jsonData: {
                mainTable: "",
                title: "",
                descrizione: "",
                sqlQuery: "",
                listaGroup: [],
                queryLimit: 10,
                queryOffset: 0,
                idDatasource: 1,
                queryFilters: [],
                fields: [],
                joins: [],
            },
            limitOptions: [
                { value: "5", text: "5" },
                { value: "10", text: "10" },
                { value: "20", text: "20" },
                { value: "50", text: "50" },
                { value: "100", text: "100" },
            ],
            tableFieldsFields: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 2rem",
                },
                {
                    label: "Nome",
                    key: "name",
                    sortable: true,
                },
                {
                    label: "Alias",
                    key: "alias",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "", thStyle: "width: 5rem" },
            ],
            fields: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tabella",
                    key: "text",
                    sortable: true,
                },
                { class: "sa-table-column-action-double", key: "actions", label: "", thStyle: "width: 5rem" },
            ],
            tablesInfoSelected: [],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadTables();
        me.loadData();
        this.$store.dispatch("dataanalysisTablesStore/init", null, { root: true }).then(() => {
            // console.log(this.$store.getters["dataanalysisTablesStore/getTablesInfo"]);
        });
    },
    computed: {
        tablesInfo() {
            return this.$store.getters["dataanalysisTablesStore/getTablesInfo"];
        },
    },
    methods: {
        getAlternateClsaa(index) {
            return index % 2 == 0 ? "sa-page-alternate-bg-laight" : "sa-page-alternate-bg-dark";
        },
        loadTables() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesTables;
            axios.get(link).then((response) => {
                // me.tablesInfo = response.data.data.list;
                response.data.data.list.forEach((element) => {
                    element.tableFields = [];
                    me.tablesInfo.push(element);
                });
            });
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        response.data.data.fields.forEach((element) => {
                            delete element.primaryKey;
                            delete element.createUser;
                            delete element.createDate;
                            delete element.updateUser;
                            delete element.updateDate;
                            delete element.proprietario;
                            delete element.id;
                            delete element.idQuery;
                        });
                        me.jsonData = response.data.data;
                        me.setData();
                        me.createQuery();
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        setData() {
            let me = this;
            me.loadTablesInfo(me.jsonData.mainTable, me.setTablesJoin());
        },
        setTablesJoin() {
            let me = this;
            me.setTablesInfoSelected();
        },
        setTablesInfoSelected() {
            console.log("OK");
            let me = this;
            me.tablesInfoSelected = [];
            let tableInfo = me.getTableInfo(me.jsonData.mainTable);
            if (tableInfo) {
                me.tablesInfoSelected.push(tableInfo);
            }
            if (me.jsonData.joins) {
                me.jsonData.joins.forEach((element) => {
                    me.loadTablesInfo(element.tableRight, null);
                    me.tablesInfoSelected.push(me.getTableInfo(element.tableRight));
                });
            }
            console.log(me.tablesInfoSelected);
        },
        onRowFieldsSelected(items) {
            let me = this;
            me.jsonData.fields = items;
            me.createQuery();
        },
        createQuery() {
            let me = this;
            me.jsonData.sqlQuery = "";
            let fields = "";
            let alias = "";
            if (me.jsonData.mainTable) {
                // me.tabellaSelezionata = me.jsonData.mainTable;
                let joins = "";
                let where = "";

                if (me.jsonData.fields.length > 0) {
                    me.jsonData.fields.forEach((element) => {
                        alias = element.alias === "" ? alias : ' AS "' + element.alias + '"';
                        if (fields === "") {
                            fields = fields + element.tableName + "." + element.fieldName + alias;
                        } else {
                            fields = fields + ", " + element.tableName + "." + element.fieldName + alias;
                        }
                    });
                } else {
                    fields = "*";
                }

                if (me.jsonData.joins.length > 0) {
                    me.jsonData.joins.forEach((element) => {
                        joins = " " + element.typeJoin + " " + element.tableRight + " ON " + element.tableLeft + "." + element.fieldLeft + " = " + element.tableRight + "." + element.fieldRight;
                    });
                }

                if (me.jsonData.queryFilters.length > 0) {
                    me.jsonData.queryFilters.forEach((element) => {
                        if (where === "") {
                            where = " WHERE " + element.tableName + "." + element.fieldName + " " + element.operatorName + " " + element.value;
                        } else {
                            where = where + " " + element.filterOperation + " " + element.tableName + "." + element.fieldName + " " + element.operatorName + " " + element.value;
                        }
                    });
                }
                me.jsonData.sqlQuery = "SELECT " + fields + " FROM " + me.jsonData.mainTable + joins + where + " LIMIT " + me.jsonData.queryLimit;
            }
        },
        onFielsdSelection() {
            let me = this;
            console.log(me.jsonData.mainTable);
            let tableInfo = me.getTableInfo(me.jsonData.mainTable);
            me.setTablesInfoSelected();
            if (!tableInfo.tableFields) {
                me.loadTablesInfo(me.jsonData.mainTable, me.mdlFieldsSelectionShow);
            } else {
                me.$refs.mdlFieldsSelection.show();
            }
            console.log("Tutto OK!");
        },
        mdlFieldsSelectionShow() {
            let me = this;
            me.$refs.mdlFieldsSelection.show();
        },
        onMdlFieldsSelectionOk() {
            let me = this;
            me.createQuery();
        },
        onRemoveFilter(index) {
            let me = this;
            me.jsonData.queryFilters.splice(index, 1);
        },
        onClickJoinsEdit() {
            let me = this;
            me.$refs.mdlJoinEdit.show();
        },
        onClickFiltersEdit() {
            let me = this;
            me.$refs.mdlFiltersEdit.show();
        },
        onMdlJoinsSelectionOk(bvModalEvent) {
            let me = this;
            if (this.$refs.cmpJoinComponent.isValid()) {
                me.jsonData.joins.push(this.$refs.cmpJoinComponent.getJoin());
                me.createQuery();
            } else {
                bvModalEvent.preventDefault();
            }
        },
        onMdlFiltersSelectionOk(bvModalEvent) {
            let me = this;
            if (this.$refs.cmpFilterComponent.isValid()) {
                me.jsonData.queryFilters.push(this.$refs.cmpFilterComponent.getWhere());
                me.createQuery();
            } else {
                bvModalEvent.preventDefault();
            }
        },
        onRemoveJoin(index) {
            let me = this;
            me.jsonData.joins.splice(index, 1);
            me.createQuery();
        },
        onChangeMainTableSelection(value) {
            let me = this;
            me.loadTablesInfo(value);
        },
        loadTablesInfo(tableName, afterLoadData = null) {
            this.$store.dispatch("dataanalysisTablesStore/setTableFields", { tableName: tableName }, { root: true }).then(() => {
                if (afterLoadData !== null) {
                    afterLoadData();
                }
            });
        },
        getTableInfo(tableName) {
            let me = this;
            let returnValue = null;
            me.tablesInfo.forEach((element) => {
                if (element.dbName === tableName) {
                    returnValue = element;
                }
            });
            return returnValue;
        },
        onMdlFiledsSelectionShow() {
            let me = this;
            me.jsonData.fields.forEach((element) => {
                me.tablesInfoSelected.forEach((tableInfo) => {
                    tableInfo.tableFields.forEach((fieldInfo) => {
                        if (fieldInfo.tableName === element.tableName && fieldInfo.fieldName === element.fieldName) {
                            fieldInfo.alias = element.alias;
                        }
                    });
                });
            });
        },
        onChangeLimit() {
            let me = this;
            me.createQuery();
        },
        onClickEseguiQuery() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceEseguiQuery;
            axios
                .post(link, me.jsonData)
                .then((response) => {
                    me.resultQueryitems = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style></style>
