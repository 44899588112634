import ProtocolloComponent from "./components/ProtocolloComponent";
import ProtocolloListComponent from "./components/ProtocolloListComponent";
import ProtocolloUtentiList from "./pages/ProtocolloUtentiList";
import ProtocolloUtentiView from "./pages/ProtocolloUtentiView";
import ProtocolloUtentiEdit from "./pages/ProtocolloUtentiEdit";
import ProtocolloModuliList from "./pages/ProtocolloModuliList";

export default {
    ProtocolloComponent,
    ProtocolloListComponent,
    ProtocolloUtentiList,
    ProtocolloUtentiView,
    ProtocolloUtentiEdit,
    ProtocolloModuliList,
};
