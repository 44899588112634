<template>
  <sa-page-layout :btnEditVisible="btnEditVisible" :linkedit="linkedit" :btnBackVisible="true" :linkback="linkback" :btnPrintVisible="true" :linkPrintData="linkPrintData" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label_data">Ambulatorio<span style="color: red">*</span></label>
              <span class="sa-label-data">{{ ambulatorio }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label_data">Prestazione<span style="color: red">*</span></label>
              <span class="sa-label-data">{{ prestazione }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblFiscalCode") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.identificativo }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblLastname") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.cognome }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblName") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.nome }}</span>
            </b-col>

            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblBirthDate") }}
                <span style="color: red">*</span>
              </label>
              <div id="date-picker-1" class="red-border">
                <span class="sa-label-data">{{ formatDate(jsonData.accettazioneAnagrafica.dataNascita) }}</span>
              </div>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblGender") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.sesso }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">{{ this.$i18n.t("patients.lblBloodGroup") }}</label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.gruppoSanguigno }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblProvince") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.provinciaNascita }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblCity") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.comuneNascita }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ this.$i18n.t("patients.lblProvince") }}<span style="color: red">*</span></label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.provinciaResidenza }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ this.$i18n.t("patients.lblCity") }}<span style="color: red">*</span></label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.comuneResidenza }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data"> {{ this.$i18n.t("patients.lblPostalCode") }}<span style="color: red">*</span></label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.capResidenza }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ this.$i18n.t("patients.lblAddress") }}<span style="color: red">*</span></label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.indirizzoResidenza }}</span>
            </b-col>
            <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12">
              <b-form-checkbox v-model="isDomicilioUgualeResidenza" name="check-button" switch>
                <h5 class="sa-text-left domicilio-residenza-checkbox">{{ this.$i18n.t("patient.lblIsDomicilioEqualResidenza") }}</h5>
              </b-form-checkbox>
            </b-col> -->
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Domicilio" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblProvince") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.provinciaDomicilio }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblCity") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.comuneDomicilio }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblPostalCode") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.capDomicilio }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblAddress") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.indirizzoDomicilio }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblTelephoneNumber") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.telefono }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">
                {{ this.$i18n.t("patients.lblEmail") }}
                <span style="color: red">*</span>
              </label>
              <span class="sa-label-data">{{ jsonData.accettazioneAnagrafica.email }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ this.$i18n.t("patients.lblNote") }}</label>
              <span class="sa-label-data">{{ jsonData.note }}</span>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import "vue2-datepicker/index.css";
import moment from "moment";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  mixins: [UtilityMixin],
  data() {
    return {
      btnEditVisible: false,
      stateIdentificativo: false,
      linkPrintData: "",
      selectOptionGruppoSanguigno: this.$i18n.t("patients.selectGruopBlood"),
      selectOptionRegione: this.$i18n.t("patients.selectRegions"),
      selectOptionSesso: this.$i18n.t("patients.selectGender"),
      selectOptionComune: this.$i18n.t("patients.selectCounties"),
      selectOptionProvincia: this.$i18n.t("patients.selectCity"),
      pathResource: "/accettazioni",
      pathResourcePaziente: "/pazienti",
      linkback: null,
      linkedit: "",
      linkProvince: "/province",
      pathResourceRegioni: "/regioni",
      linkComune: "/comuni/provincia",
      linkComuneByIstat: "/comuni/istat",
      linkGruppiSangue: "/pazientigruppisanguigno",
      pathResourcePrestazioni: "/ambulatoriprestazioninomenclatore",
      pathResourceAmbulatori: "/ambulatoriutenti",
      prestazione: null,
      ambulatorio: null,
      ambulatoriOptions: [],
      showModalLoading: false,
      id: "-1",
      profili: [],
      sessoOption: [],
      regioniOptions: [],
      isDomicilioUgualeResidenza: false,
      provinciaNascitaOption: [],
      provinciaDomicilioOption: [],
      provinciaResidenzaOption: [],
      comuneNascitaOption: [],
      prestazioniOptions: [],
      listaPrestazioni: [],
      comuneDomicilioOption: [],
      comuneResidenzaOption: [],
      language: null,
      gruppoSanguignoOption: [],
      identificationDimension: 0,
      // accettazionePrestazioni: {
      //   idAccettazione: null,
      //   idAmbulatorio: null,
      //   codiceRegione: null,
      //   codicePrestazioneAgg: null,
      //   codiceBranca: null,
      //   codicePrestazione: null,
      //   descrizione: null,
      //   descrizioneAgg: null,
      //   nota: null,
      //   quantita: 0,
      //   tipoEsame: null,
      //   concedibile: 0,
      //   categoria: 0,
      //   valore: 0,
      //   lea: 0,
      //   sottoBranca: null,
      //   codicePrestazioneControllo: null,
      //   importoPrestazione: 0,
      //   qtaPrescrivibile: 0,
      //   dataInizioValidita: null,
      //   dataFineValidita: null,
      //   codiceAggAttivo: null,
      //   notaAppropriatezza: null,
      //   condizioneErogabilita: null,
      //   indicazioneAppropriatezza: null,
      //   pngla: null,
      //   statoAttuale: null,
      //   statoAttualeDataOra: null,
      // },
      jsonData: {
        codiceStruttura: "000000",
        numero: "",
        statoAttuale: "",
        statoAttualeDataOra: null,
        note: "",
        tipo: "",
        idPrenotazione: "",
        accettazioneStruttura: {},
        accettazioneAnagrafica: {
          idAccettazione: "",
          tipo: "",
          identificativo: "",
          tipoIdentificativo: "",
          cognome: "",
          nome: "",
          dataNascita: null,
          comuneNascitaCodiceIstat: null,
          comuneResidenzaCodiceIstat: null,
          comuneDomicilioCodiceIstat: null,
          provinciaDomicilio: null,
          provinciaResidenza: null,
          provinciaNascita: null,
          indirizzoResidenza: "",
          indirizzoDomicilio: "",
          note: "",
          medicoCurante: "",
          codiceFiscaleMedicoCurante: "",
          capDomicilio: null,
          capResidenza: null,
          telefono: "",
          email: "",
          sesso: null,
        },
        accettazioneStati: [],
        accettazionePrestazioni: [],
      },
    };
  },
  computed: {
    dataScadenzaUtenza: {
      get: function () {
        return new Date(this.data.scadenzaUtenza);
      },
      set: function (value) {
        this.data.scadenzaUtenza = moment(String(value)).valueOf();
      },
    },
  },
  mounted: function () {
    let me = this;
    me.identificationDimension = this.appConfig.identificationDimension;
    me.id = this.$route.params.id;
    me.language = this.$route.query.language;
    me.linkback = "/accettazioneabruzzo/";
    me.linkedit = "/accettazioneabruzzo/edit/" + me.id;
    me.impostaPermessi();
    me.loadAmbulatori();
    me.loadDefaultData();
  },
  watch: {
    isDomicilioUgualeResidenza(value) {
      let me = this;
      if (value) {
        me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = me.jsonData.accettazioneAnagrafica.indirizzoResidenza;
        me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
        me.jsonData.accettazioneAnagrafica.provinciaDomicilio = me.jsonData.accettazioneAnagrafica.provinciaResidenza;
        me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
        // console.log(me.data);
      } else if (!value) {
        (me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = null), (me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = null), (me.jsonData.accettazioneAnagrafica.provinciaDomicilio = null), (me.jsonData.accettazioneAnagrafica.capDomicilio = null);
      }
      // console.log(me.data);
    },
  },

  methods: {
    onDataChange(input, id) {
      let me = this;
      me.checkState(input, id);
    },
    checkState(input, id) {
      // console.log(typeof input);
      if (typeof input == "number") {
        document.getElementById(id).classList.remove("red-border");
        document.getElementById(id).classList.add("green-border");
      } else {
        document.getElementById(id).classList.remove("green-border");
        document.getElementById(id).classList.add("red-border");
      }
    },
    loadDefaultData() {
      let me = this;
      me.loadAmbulatori();
      me.loadData();
    },
    loadSesso() {
      let link = process.env.VUE_APP_PATH_API + "/anagraficasesso";
      axios.get(link).then((res) => {
        let sessi = res.data.data;
        sessi.forEach((element) => {
          let obj = { value: element.codice, text: this.$i18n.t(element.descrizione) };
          this.sessoOption.push(obj);
        });
      });
    },
    loadData() {
      let me = this;

      //console.log(me.id);
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios.get(link + me.id).then((response) => {
        me.jsonData = response.data.data;
        me.linkPrintData = "/accettazioniprinter/" + me.jsonData.id;
        me.loadComuneNascita(me.jsonData.accettazioneAnagrafica.provinciaNascita);
        me.loadComuneResidenza(me.jsonData.accettazioneAnagrafica.provinciaResidenza);
        me.loadComuneDomicilio(me.jsonData.accettazioneAnagrafica.provinciaDomicilio);
        me.prestazione = me.jsonData.accettazionePrestazioni[0].descrizione + " (" + me.jsonData.accettazionePrestazioni[0].codicePrestazione + ")";
        me.getAmbulatorio();
        me.showModalLoading = false;
      });
    },
    getAmbulatorio() {
      let me = this;
      me.ambulatoriOptions.forEach((element) => {
        if (element.id === me.jsonData.accettazionePrestazioni[0].idAmbulatorio) {
          // console.log();
          me.ambulatorio = element.nome;
        }
      });
    },
    loadRegioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni;
      axios.get(link).then((response) => {
        me.regioniOptions = response.data.data;
      });
    },
    loadProvinceNascita() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaNascitaOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadGruppiSangue() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkGruppiSangue;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({ value: element.descrizione, text: this.$i18n.t(element.descrizione) });
        });
      });
    },
    loadProvinceDomicilio() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaDomicilioOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadProvinceResidenza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinciaResidenzaOption.push({ value: element.sigla, text: element.provincia });
        });
      });
    },
    loadComuneNascita(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneNascitaOption = [];
        response.data.data.forEach((element) => {
          me.comuneNascitaOption.push({ value: element.codiceIstat, text: element.comune });
          if (element.codiceIstat == me.jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat) {
            me.jsonData.accettazioneAnagrafica.comuneNascita = element.comune;
          }
        });
      });
    },
    loadComuneDomicilio(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneDomicilioOption = response.data.data;
        me.comuneDomicilioOption.forEach((element) => {
          if (element.codiceIstat == me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat) {
            me.jsonData.accettazioneAnagrafica.comuneDomicilio = element.comune;
          }
        });
      });
    },
    setCapDomicilio(item) {
      let me = this;
      me.comuneDomicilioOption.forEach((comuneDomicilio) => {
        if (comuneDomicilio.codiceIstat === item) {
          me.jsonData.accettazioneAnagrafica.capDomicilio = comuneDomicilio.cap;
        }
      });
    },
    loadComuneResidenza(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuneResidenzaOption = response.data.data;
        me.comuneResidenzaOption.forEach((element) => {
          if (element.codiceIstat == me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat) {
            me.jsonData.accettazioneAnagrafica.comuneResidenza = element.comune;
          }
        });
      });
    },
    setCapResidenza(item) {
      let me = this;
      me.comuneResidenzaOption.forEach((comuneResidenza) => {
        if (comuneResidenza.codiceIstat === item) {
          me.jsonData.accettazioneAnagrafica.capResidenza = comuneResidenza.cap;
        }
      });
    },
    loadAmbulatori() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatori;
      me.ambulatoriOptions = [];
      axios.get(link).then((response) => {
        me.ambulatoriOptions = response.data.data;
      });
    },
    onValidazioneIdentificativo(value) {
      let me = this;
      me.validazioneIdentificativo(value);
    },
    validazioneIdentificativo(value) {
      let me = this;
      if (value.length != me.identificationDimension) {
        me.stateIdentificativo = false;
        return;
      }
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + "/anagrafica/validazioneidentificativo?identificativo=" + value;
      axios
        .get(link)
        .then(() => {
          // me.btnSaveVisible = true;
          me.stateIdentificativo = true;
          me.showModalLoading = false;
        })
        .catch(() => {
          // me.btnSaveVisible = false;
          me.stateIdentificativo = false;
          me.showModalLoading = false;
        });
    },
    impostaPermessi() {
      let me = this;
      me.btnEditVisible = false;
      me.btnEditVisible = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "accettazione", 4);
    },
    onSelectPrestazione() {
      let me = this;
      me.listaPrestazioni.forEach((element) => {
        if (element.codicePrestazione === me.prestazione) {
          // me.accettazionePrestazioni.codiceRegione = element.codiceRegione;
          // me.accettazionePrestazioni.codicePrestazioneAgg = element.codicePrestazioneAgg;
          // me.accettazionePrestazioni.codiceBranca = element.codiceBranca;
          // me.accettazionePrestazioni.codicePrestazione = element.codicePrestazione;
          // me.accettazionePrestazioni.descrizione = element.descrizione;
          // me.accettazionePrestazioni.descrizioneAgg = element.descrizioneAgg;
          // me.accettazionePrestazioni.nota = element.nota;
          // me.accettazionePrestazioni.quantita = 1; // chiedere a sisto
          // me.accettazionePrestazioni.tipoEsame = element.tipoEsame;
          // me.accettazionePrestazioni.concedibile = element.concedibile;
          // me.accettazionePrestazioni.categoria = element.categoria;
          // me.accettazionePrestazioni.valore = element.valore;
          // me.accettazionePrestazioni.lea = element.lea;
          // me.accettazionePrestazioni.sottoBranca = element.sottoBranca;
          // me.accettazionePrestazioni.codicePrestazioneControllo = element.codicePrestazioneControllo;
          // me.accettazionePrestazioni.importoPrestazione = element.importoPrestazione;
          // me.accettazionePrestazioni.qtaPrescrivibile = element.qtaPrescrivibile;
          // me.accettazionePrestazioni.dataInizioValidita = element.dataInizioValidita;
          // me.accettazionePrestazioni.dataFineValidita = element.dataFineValidita;
          // me.accettazionePrestazioni.codiceAggAttivo = element.codiceAggAttivo;
          // me.accettazionePrestazioni.notaAppropriatezza = element.notaAppropriatezza;
          // me.accettazionePrestazioni.condizioneErogabilita = element.condizioneErogabilita;
          // me.accettazionePrestazioni.indicazioneAppropriatezza = element.indicazioneAppropriatezza;
          // me.accettazionePrestazioni.pngla = element.pngla;
          me.jsonData.accettazionePrestazioni.push(element);
        }
      });
    },
  },
};
</script>
<style>
.red-border input {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
.green-border input {
  border: 1px solid #28a745;
  border-radius: 5px;
}
.domicilio-residenza-checkbox {
  margin-top: 0.2rem;
}
</style>
