<template>
  <sa-page-layout ref="pgaView" :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :showmolalloading="showmolalloading">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <h5 class="sa-text-left">{{ getLabelTraduora("bancaEmatica.lblNewPack") }}</h5>
          <b-row v-if="isRichiestaReparto">
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">Codice Paziente</span>
              <b-form-input v-model="richiestaReparto.cartellaClinica" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">Reparto</span>
              <b-form-input v-model="richiestaReparto.reparto" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">Tipologia Sacca</span>
              <b-form-input v-model="richiestaReparto.tipologiaSacca" disabled></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblRequest") }}</span>
              <b-input-group>
                <b-form-input v-model="datiRichiesta" :placeholder="getLabelTraduora('bancaEmatica.lblRequest')" readonly></b-form-input>
                <b-button size="sm" variant="" v-b-modal.ricercaRichiesta @click="onClick">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPackCode") }}</span>
              <b-form-input ref="codiceSacca" v-model="data.codiceSacca" :placeholder="getLabelTraduora('bancaEmatica.lblPackCode')"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblTypeOfBlood") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.tipologia" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaOption" :value="null"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-form-label">{{ getLabelTraduora("sequenziamento.lblstatoAttuale") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPackType") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.tipologiaSacca" class="mb-2 mr-sm-2 mb-sm-0" :options="tipologiaSaccaOption" :value="null" @input="onTipologiaSaccaInput()"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblBloodGroup") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null" :disabled="disabilitato"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("patient.afa.lblRHFactor") }}</span>
              <b-form-select id="inline-form-custom-select-pref" v-model="data.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null" :disabled="disabilitato"></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblExpirationDate") }}</span>
              <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" @focus="onFocusDataScadenza" v-model="data.dataScadenza" type="datetime" :placeholder="getLabelTraduora('bancaEmatica.lblExpirationDate')"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblHB") }}</span>
              <b-form-input v-model="data.hb" placeholder="HB"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblINR") }}</span>
              <b-form-input v-model="data.inr" placeholder="INR"></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPT") }}</span>
              <b-form-input v-model="data.pt" placeholder="PT"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblPIT") }}</span>
              <b-form-input v-model="data.pit" placeholder="PTT"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <span class="sa-form-label">{{ getLabelTraduora("bancaEmatica.lblAPTT") }}</span>
              <b-form-input v-model="data.aptt" placeholder="APTT"></b-form-input>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal id="ricercaRichiesta" refs="ricercaRichiesta" size="lg" title="Ricerca Richiesta" @show="onShow" @ok="onOk">
        <b-input-group class="mb-3">
          <b-form-input v-model="numeroRichiesta" :placeholder="getLabelTraduora('bancaEmatica.lblRequestNumber')"></b-form-input>
          <b-button size="sm" variant="" v-b-modal.ricercaRichiesta @click="onClick">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
        <b-table id="tabellaRichieste" :items="tabellaRichieste" :fields="richiesteFields" selectable select-mode="single" @row-dblclicked="onDoubleClickRow" @row-selected="onRowSelected">
          <template v-slot:cell(gruppoSanguigno)="{ item }">
            <a> {{ item.gruppoSanguigno }} {{ onModificaFattoreRh(item.fattoreRh) }} </a>
          </template>
        </b-table>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker, SaPageLayout },
  data() {
    return {
      disabledBefore: new Date(),
      pathResource: "/bancheematiche",
      pathResourceRichiestaReparto: "/bancaematicatrasfusionirichiestereparti",
      linkback: null,
      linkstato: "/bancheematichestatitipi",
      linkgrupposanguigno: "/bancheematichegruppisanguigni",
      linktipologie: "/bancheematichetipiemoderivatitipologie",
      linktipologiesacche: "/bancaematicatipologiesacche",
      linkfattorerh: "/bancaematicafattorirh",
      pathResourceAggiornaStato: "/aggiornaStato",
      showmolalloading: false,
      isRichiestaReparto: false,
      id: "-1",
      currentPage: 1,
      rows: 3,
      perPage: 50,
      disabilitato: false,
      profili: [],
      filtro: {},
      richiestaReparto: {},
      statoOption: [{ value: null, text: "--Seleziona Valore--" }],
      gruppoSanguignoOption: [{ value: null, text: "--Seleziona Valore--" }],
      fattoreRhOption: [{ value: null, text: "--Seleziona Valore--" }],
      tipologiaOption: [{ value: null, text: "--Seleziona Valore--" }],
      tipologiaSaccaOption: [{ value: null, text: "--Seleziona Valore--" }],
      tabellaRichieste: [],
      rigaSelezionata: null,
      numeroRichiesta: null,
      pathRestRichieste: "/bancaematicarichieste",
      data: { dataScadenza: null, statoAttuale: null, gruppoSanguigno: null, fattoreRh: null, idBancaEmaticaTrasfusioneRichiestaScorte: null, tipologiaSacca: null, tipologia: null, bancaEmaticaRichiestaBean: { numeroRichiesta: "" } },
      richiesteFields: [
        {
          label: this.getLabelTraduora("bancaEmatica.lblRequestNumber"),
          key: "numeroRichiesta",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblBloodGroup"),
          key: "gruppoSanguigno",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblPackType"),
          key: "tipologiaSacca",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblRequestDate"),
          key: "dataRichiesta",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY");
            }
          },
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblTypeOfBlood"),
          key: "tipo",
          sortable: true,
          thStyle: "width: 4rem",
        },
      ],
    };
  },
  computed: {
    datiRichiesta: {
      get() {
        let me = this;
        let richiesta = me.data.bancaEmaticaRichiestaBean.numeroRichiesta;
        return richiesta;
      },
      set() {},
    },
  },
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/bancaematica";
    } else {
      me.linkback = "/bancaematica/view/" + me.id;
    }
    me.loadDefaultData();
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadData();
      me.loadFattoreRh();
      me.loadGruppoSanguigno();
      me.loadTipologia();
      me.loadTipologiaSacca();
      me.loadStato();
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showmolalloading = false;
      } else {
        //me.showmolalloading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.data = response.data.data;
          me.showmolalloading = false;
        });
      }
    },
    loadStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkstato;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.statoOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    loadGruppoSanguigno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    loadTipologia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linktipologie;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.tipologiaOption.push({ value: element.tipologia, text: element.tipologia });
        });
      });
    },
    loadTipologiaSacca() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linktipologiesacche;
      axios.get(link).then((response) => {
        // console.log(response.data.data);
        response.data.data.forEach((element) => {
          me.tipologiaSaccaOption.push({ value: element.tipologiaSacca, text: element.tipologiaSacca });
        });
      });
    },
    loadFattoreRh() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.fattoreRhOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else if (valore === "NEGATIVO") {
        return "-";
      } else {
        return "";
      }
    },
    onShow() {
      let me = this;
      me.tabellaRichieste = [];
    },
    onClose() {
      this.$refs.codiceSacca.focus();
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rigaSelezionata = items[0];
      }
    },
    onDoubleClickRow() {
      let me = this;
      me.inputValori();
      this.$root.$emit("bv::hide::modal", "ricercaRichiesta", "");
      setTimeout(function() {
        me.$refs.codiceSacca.focus();
      }, 500);
      me.data.statoAttuale = "DISPONIBILE";
    },
    onOk() {
      let me = this;
      me.inputValori();
      setTimeout(function() {
        me.$refs.codiceSacca.focus();
      }, 500);
      me.data.statoAttuale = "DISPONIBILE";
    },
    inputValori() {
      let me = this;
      me.isRichiestaReparto = false;
      me.data.bancaEmaticaRichiestaBean = me.rigaSelezionata;
      me.datiRichiesta = me.rigaSelezionata;
      //me.data.numeroRichiesta = me.rigaSelezionata.numeroRichiesta;
      me.data.idBancaEmaticaTrasfusioneRichiestaScorte = me.rigaSelezionata.id;
      me.data.hb = me.rigaSelezionata.hb;
      me.data.inr = me.rigaSelezionata.inr;
      me.data.pt = me.rigaSelezionata.pt;
      me.data.pit = me.rigaSelezionata.pit;
      me.data.aptt = me.rigaSelezionata.aptt;
      me.data.gruppoSanguigno = me.rigaSelezionata.gruppoSanguigno;
      me.data.fattoreRh = me.rigaSelezionata.fattoreRh;
      me.data.tipologia = me.rigaSelezionata.tipologia;
      me.data.tipologiaSacca = me.rigaSelezionata.tipologiaSacca;
      me.data.provenienzaRichiesta = me.rigaSelezionata.tipo;
      if (me.rigaSelezionata.numeroRichiesta.includes("RR")) {
        me.isRichiestaReparto = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResourceRichiestaReparto + "/" + me.rigaSelezionata.id;
        console.log(link);
        axios.get(link).then((response) => {
          me.richiestaReparto = response.data.data;
        });
      }
    },
    onFocusDataScadenza() {
      let me = this;
      console.log("OK");
      if (!me.data.dataScadenza) {
        me.data.dataScadenza = new Date().getTime();
        console.log(me.data.dataScadenza);
      }
    },
    onTipologiaSaccaInput() {
      let me = this;
      me.disabilitato = false;
      if (me.data.tipologiaSacca === "Piastrine") {
        me.disabilitato = true;
      }
    },
    onClick() {
      let me = this;
      me.filtro.forPage = me.perPage;
      me.filtro.page = me.currentPage;
      me.filtro.numeroRichiesta = me.numeroRichiesta;
      let link = process.env.VUE_APP_PATH_API + me.pathRestRichieste + "?statoAttuale=RICHIESTA";
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.rows = response.data.data.recordsNumber;
          me.tabellaRichieste = response.data.data.list;
          me.showmolalloading = false;
          me.numeroRichiesta = null;
        })
        .catch((error) => {
          me.showmolalloading = false;
          me.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
  },
};
</script>
