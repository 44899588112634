import BancaEmaticaList from "./components/BancaEmaticaList.vue";
import BancaEmaticaEdit from "./components/BancaEmaticaEdit.vue";
import BancaEmaticaView from "./components/BancaEmaticaView.vue";

import BancaEmaticaRichiestaScorteList from "./components/BancaEmaticaRichiestaScorteList.vue";
import BancaEmaticaRichiestaScorteView from "./components/BancaEmaticaRichiestaScorteView.vue";
import BancaEmaticaRichiestaScorteEdit from "./components/BancaEmaticaRichiestaScorteEdit.vue";


import BancaEmaticaRichiesteRepartoList from "./components/BancaEmaticaRichiesteRepartoList.vue"
import BancaEmaticaRichiesteRepartoEdit from "./components/BancaEmaticaRichiesteRepartoEdit.vue"
import BancaEmaticaRichiesteRepartoView from "./components/BancaEmaticaRichiesteRepartoView.vue"

import BancaEmaticaRepartiList from "./components/BancaEmaticaRepartiList.vue";
import BancaEmaticaRepartiEdit from "./components/BancaEmaticaRepartiEdit.vue";
import BancaEmaticaRepartiView from "./components/BancaEmaticaRepartiView.vue";

import BancaEmaticaOperatoriList from "./components/BancaEmaticaOperatoriList.vue";
import BancaEmaticaOperatoriEdit from "./components/BancaEmaticaOperatoriEdit.vue";
import BancaEmaticaOperatoriView from "./components/BancaEmaticaOperatoriView.vue";

import BancaEmaticaTipiOperatoriList from "./components/BancaEmaticaTipiOperatoriList.vue";
import BancaEmaticaTipiOperatoriEdit from "./components/BancaEmaticaTipiOperatoriEdit.vue";
import BancaEmaticaTipiOperatoriView from "./components/BancaEmaticaTipiOperatoriView.vue";
import BancaEmaticaTotali from "./components/BancaEmaticaTotali.vue";
import BancaEmaticaRichiesteRepartiTotaliList from "./components/BancaEmaticaRichiesteRepartiTotaliList.vue";



export default {
    BancaEmaticaList,
    BancaEmaticaRepartiList,
    BancaEmaticaOperatoriList,
    BancaEmaticaTipiOperatoriList,
    BancaEmaticaEdit,
    BancaEmaticaRepartiEdit,
    BancaEmaticaOperatoriEdit,
    BancaEmaticaTipiOperatoriEdit,
    BancaEmaticaView,
    BancaEmaticaRepartiView,
    BancaEmaticaOperatoriView,
    BancaEmaticaTipiOperatoriView,
    BancaEmaticaRichiesteRepartoList,
    BancaEmaticaRichiesteRepartoEdit,
    BancaEmaticaRichiesteRepartoView,
    BancaEmaticaRichiestaScorteList,
    BancaEmaticaRichiestaScorteView,
    BancaEmaticaRichiestaScorteEdit,
    BancaEmaticaTotali,
    BancaEmaticaRichiesteRepartiTotaliList,
};
