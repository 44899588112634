<template>
  <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <div style="float: right">
        <print-component :reportName="reportName" :data="items"></print-component>
      </div>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>Codice Sacca:</strong>
            <b-form-input v-model="filtro.codiceSacca" type="search" id="nome" :placeholder="getLabelTraduora('bancaEmatica.lblPackCode')"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>Stato Attuale:</strong>
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
          </b-col>
          <!-- </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem"> -->
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>Gruppo Sanguigno:</strong>
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <strong>Fattore Rh:</strong>
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null"></b-form-select>
          </b-col>
          <!-- </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem"> -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>Scorta/Reparto:</strong>
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.provenienzaRichiesta" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoRichiestaOptions" :value="null"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>Data Dal:</strong>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="date" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <strong>Data Al:</strong>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="date" placeholder="Data Al"></date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ getLabelTraduora("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ getLabelTraduora("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>

    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" fixed stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(codiceSacca)="{ item }">
            <router-link class="sa-edit-link" :to="'/bancaematica/view/' + item.id"> {{ item.codiceSacca }} </router-link>
          </template>
          <template v-slot:cell(gruppoSanguigno)="{ item }">
            <a> {{ item.gruppoSanguigno }} {{ onModificaFattoreRh(item.fattoreRh) }} </a>
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onCambioStato(row.item, row.index, $event.target)">
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal ref="tstModal" size="lg" id="my-modal" @ok="onOk" @hidden="onHiddenModal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("bancaEmatica.lblState") }}:</strong>
            <b-form-select id="inline-form-custom-select-pref" v-model="data.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null" @input="onInputStatoSacca($event)"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{ getLabelTraduora("bancaEmatica.lblStartNewRequest") }}:</strong><br />
            <b-form-checkbox v-model="data.rieffettuaRichiesta" name="check-button" size="lg" :disabled="disabilitatoRichiesta"></b-form-checkbox>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <strong>{{ getLabelTraduora("bancaEmatica.lblReceiver") }}:</strong><br />
            <b-form-select v-model="data.destinatario" class="mb-2 mr-sm-2 mb-sm-0" :options="destinatarioOption" :value="null" :disabled="disabilitatoDestinatario"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <strong>{{ getLabelTraduora("bancaEmatica.lblNotes") }}:</strong><br />
            <b-form-textarea id="textarea-no-resize" v-model="data.note" placeholder="Inserisci Note" rows="3" no-resize></b-form-textarea>
          </b-col>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  components: { PrintComponent, SaPageLayout, DatePicker },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      pathResource: "/bancheematiche",
      linkedit: "/bancaematica",
      linkstato: "/bancheematichestatitipi",
      linkdestinatario: "/bancaematicareparti",
      linkgrupposanguigno: "/bancheematichegruppisanguigni",
      linkfattorerh: "/bancaematicafattorirh",
      pathResourceAggiornaStato: "/aggiornaStato",
      reportName: "ReportListaBancaEmatica",
      showModalLoading: false,
      perPage: 10,
      currentPage: 1,
      disabilitatoRichiesta: true,
      disabilitatoDestinatario: true,
      note: "",
      filter: null,
      id: "-1",
      filtro: { statoAttuale: null, gruppoSanguigno: null, fattoreRh: null, provenienzaRichiesta: null },
      filterOn: [],
      fields: [
        {
          label: this.getLabelTraduora("bancaEmatica.lblPackCode"),
          key: "codiceSacca",
          sortable: true,
          thStyle: "width: 3rem",
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblPackType"),
          key: "tipologiaSacca",
          thStyle: "width: 4rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblBloodGroup"),
          key: "gruppoSanguigno",
          thStyle: "width: 3rem",
          sortable: true,
        },
        {
          label: "Scorta/Reparto",
          key: "provenienzaRichiesta",
          thStyle: "width: 3rem",
          sortable: true,
        },
        {
          label: "Stato",
          key: "statoAttuale",
          thStyle: "width: 4rem",
          sortable: true,
        },
        {
          label: "Data di Scadenza",
          key: "dataScadenza",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 2rem",
        },
        // { class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
      ],
      items: [],
      tipoRichiestaOptions: [
        { value: null, text: "TUTTI" },
        { value: "REPARTO", text: "REPARTO" },
        { value: "SCORTE", text: "SCORTE" },
      ],
      statoOption: [{ value: null, text: "-Stato Sacca-" }],
      destinatarioOption: [{ value: null, text: "-Destinatario-" }],
      gruppoSanguignoOption: [{ value: null, text: "-Gruppo Sanguigno-" }],
      fattoreRhOption: [{ value: null, text: "-Fattore Rh-" }],
      data: { stato: null, note: "", idBancaEmatica: "", destinatario: null },
    };
  },
  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadStato();
    me.loadGruppoSanguigno();
    me.loadFattoreRh();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?page=1&forPage=50";
      me.isBusy = true;
      // if (me.filtro.statoAttuale === null) {
      //   me.filtro.statoAttuale = "DISPONIBILE";
      // }
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          for (let element in me.items) {
            // console.log((me.items[element]["dataScadenza"] - Date.now()) / (1000 * 60 * 60 * 24));
            let differenza = ((me.items[element]["dataScadenza"] - Date.now()) / (1000 * 60 * 60 * 24)).toFixed(0);
            // console.log(differenza);
            me.items[element]["_cellVariants"] = {};
            if (differenza > 3) {
              me.items[element]["_cellVariants"].dataScadenza = "success";
            } else if (differenza == 3) {
              me.items[element]["_cellVariants"].dataScadenza = "warning";
            } else {
              me.items[element]["_cellVariants"].dataScadenza = "danger";
            }

            if (me.items[element]["statoAttuale"] === "DISPONIBILE") {
              me.items[element]["_cellVariants"].statoAttuale = "success";
            }
          }
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onCambioStato(item) {
      let me = this;
      me.$refs.tstModal.show();
      me.data.idBancaEmatica = item.id;
      me.data.note = item.note;
      // console.log(item);
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onOk() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + me.pathResourceAggiornaStato;
      let jsonData = JSON.stringify(me.data);
      axios.post(link, jsonData).then(() => {
        me.showModalLoading = false;
        me.loadData();
      });
    },
    onHiddenModal() {
      let me = this;
      me.data = { stato: null, note: "", idBancaEmatica: "", destinatario: null };
    },
    loadStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkstato;
      axios.get(link).then((response) => {
        // console.log(response.data.data);
        response.data.data.forEach((element) => {
          me.statoOption.push({ value: element.valore, text: element.valore });
        });
        me.filtro.statoAttuale = "DISPONIBILE";
      });
    },
    loadDestinatario() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkdestinatario + "?page=1&forPage=100000000";
      me.destinatarioOption = [{ value: null, text: "-Destinatario-" }];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.destinatarioOption.push({ value: element.descrizioneReparto, text: element.descrizioneReparto });
        });
      });
    },
    loadGruppoSanguigno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    loadFattoreRh() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.fattoreRhOption.push({ value: element.valore, text: element.valore });
        });
      });
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else if (valore === "NEGATIVO") {
        return "-";
      } else {
        return "";
      }
    },
    onInputStatoSacca(value) {
      let me = this;
      me.disabilitatoDestinatario = true;
      me.disabilitatoRichiesta = true;
      if (value === "EROGATO") {
        me.disabilitatoDestinatario = false;
        me.loadDestinatario();
        me.items.forEach((element) => {
          if (element.id === me.data.idBancaEmatica) {
            // console.log(element);
            if (element.provenienzaRichiesta === "SCORTE") {
              me.disabilitatoRichiesta = false;
            }
          }
        });
      }
    },
  },
  watch: {
    "data.stato": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value !== "EROGATO") {
          me.disabilitatoDestinatario = true;
          me.disabilitatoRichiesta = true;
        }
      },
    },
  },
};
</script>
