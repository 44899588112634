<template>
  <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :pathResource="pathResource" @refresh="onRefresh" :linkedit="linkedit">
    <template slot="toolbar">
      <div style="float: right">
        <print-component :reportName="reportName" :data="data"></print-component>
      </div>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-input v-model="filtro.numeroRichiesta" type="search" id="nome" placeholder="Numero Richiesta"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.statoAttuale" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOption" :value="null"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-select id="inline-form-custom-select-pref" v-model="filtro.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOption" :value="null"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ getLabelTraduora("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ getLabelTraduora("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>

    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>

    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :filter="filter" :items="data" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(numeroRichiesta)="{ item }">
            <router-link class="sa-edit-link" :to="'/bancaematicarichiestereparto/view/' + item.id">{{ item.numeroRichiesta }}</router-link>
            <br />
          </template>
          <template v-slot:cell(gruppoSanguigno)="{ item }">
            <a>
              {{ item.gruppoSanguigno }}
              {{ onModificaFattoreRh(item.fattoreRh) }}
            </a>
          </template>
          <template v-slot:cell(nominativo)="{ item }">
            <a> {{ item.nomePaziente }} {{ item.cognomePaziente }} </a>
          </template>
          <template v-slot:cell(medico)="{ item }">
            <p>
              {{ item.nomeMedico }} <br />
              {{ item.cognomeMedico }}
            </p>
          </template>
          <template v-slot:cell(operatore)="{ item }">
            <p>
              {{ item.nomeOperatore }}<br />
              {{ item.cognomeOperatore }}
            </p>
          </template>
          <!-- <template #cell(actions)="row">
                        <b-button size="sm" variant="btn btn-primary waves-effect  waves-light width-sm no-text" style="margin-right:3px" @click="onCambioStato(row.item, row.index, $event.target)">
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                    </template> -->
        </b-table>
      </div>
    </template>

    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal ref="tstModal" id="my-modal" @ok="onOk">
        <b-row>
          <b-form-select id="inline-form-custom-select-pref" v-model="items.stato" class="mb-2 mr-sm-2 mb-sm-0" :options="statoOption" :value="null"></b-form-select>
        </b-row>
        <b-row>
          <b-form-textarea id="textarea-no-resize" v-model="items.note" placeholder="Inserisci Note" rows="3" no-resize></b-form-textarea>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { PrintComponent, SaPageLayout },
  computed: {
    rows() {
      return this.data.length;
    },
  },
  data() {
    return {
      perPage: 10,
      pathResourceAggiornaStato: "/aggiornaStatoReparti",
      linkstato: "/bancaematicatrasfusionirichiesterepartistatitipi",
      reportName: "ReportListaRichiestaReparto",
      currentPage: 1,
      note: "",
      filter: null,
      id: "-1",
      pathResource: "/bancaematicatrasfusionirichiestereparti",
      linkedit: "/bancaematicarichiestereparto",

      linkgrupposanguigno: "/bancheematichegruppisanguigni",
      linkfattorerh: "/bancaematicafattorirh",

      filtro: { statoAttuale: null, gruppoSanguigno: null, fattoreRh: null },
      fields: [
        {
          label: this.getLabelTraduora("bancaEmatica.lblRequestNumber"),
          key: "numeroRichiesta",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblMedic"),
          key: "medico",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblOperator"),
          key: "operatore",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblPackType"),
          key: "tipologiaSacca",
          thStyle: "width: 7rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblBloodGroup"),
          key: "gruppoSanguigno",
          thStyle: "width: 6rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblDepartment"),
          key: "reparto",
          thStyle: "width: 8rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblRequestPacksNumber"),
          key: "numeroSaccheRichieste",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("bancaEmatica.lblUrgencyDegree"),
          key: "gradoUrgenza",
          thStyle: "width: 5rem",
          sortable: true,
        },

        {
          label: this.getLabelTraduora("sequenziamento.lblstatoAttuale"),
          key: "statoAttuale",
          thStyle: "width: 6rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("sequenziamento.lblstatoAttualeDataOra"),
          key: "statoAttualeDataOra",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            }
          },
          thStyle: "width: 6rem",
          sortable: true,
        },
        // {
        //     label: "",
        //     key: "actions",
        //     thStyle: "width: 3rem",
        // },
      ],
      data: [],
      statoOption: [
        { value: null, text: "-Stato-" },
        { value: "", text: "TUTTI" },
      ],
      gruppoSanguignoOption: [{ value: null, text: "--Gruppo Sanguigno--" }],
      fattoreRhOption: [{ value: null, text: "--Fattore Rh--" }],
      items: {
        stato: null,
        note: "",
        idBancaEmaticaTrasfusioneRichiestaReparto: "",
      },
    };
  },

  created: function() {},
  mounted: function() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadStato();
    me.loadGruppoSanguigno();
    me.loadFattoreRh();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?page=1&forPage=50";
      me.isBusy = true;
      if (me.filtro.statoAttuale === null) {
        me.filtro.statoAttuale = "RICHIESTA";
      }
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.data = [];
          me.data = response.data.data.list;
          for (let element in me.data) {
            me.data[element]["_cellVariants"] = {};
            if (me.data[element]["statoAttualeDataOra"] - 259200 > Date.now()) {
              me.data[element]["_cellVariants"].statoAttualeDataOra = "success";
            } else if (me.data[element]["statoAttualeDataOra"] - 259200 <= Date.now()) {
              me.data[element]["_cellVariants"].statoAttualeDataOra = "danger";
            }

            if (me.data[element]["statoAttuale"] === "RIFIUTATA" || me.data[element]["statoAttuale"] == "INCOMPLETA") {
              me.data[element]["_cellVariants"].statoAttuale = "danger";
            } else if (me.data[element]["statoAttuale"] == "EVASA") {
              me.data[element]["_cellVariants"].statoAttuale = "success";
            } else if (me.data[element]["statoAttuale"] == "RICHIESTA") {
              me.data[element]["_cellVariants"].statoAttuale = "warning";
            }
          }
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },

    // onCambioStato(item) {
    //     let me = this;
    //     me.$refs.tstModal.show();
    //     me.items.idBancaEmaticaTrasfusioneRichiestaReparto = item.id;
    //     me.items.note = item.note;
    // },
    loadStato() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkstato;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.statoOption.push({ value: element.valore, text: element.valore });
        });
        me.filtro.statoAttuale = "RICHIESTA";
      });
    },
    loadGruppoSanguigno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkgrupposanguigno;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.gruppoSanguignoOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    loadFattoreRh() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkfattorerh;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.fattoreRhOption.push({
            value: element.valore,
            text: element.valore,
          });
        });
      });
    },
    onModificaFattoreRh(valore) {
      if (valore === "POSITIVO") {
        return "+";
      } else if (valore === "NEGATIVO") {
        return "-";
      } else {
        return "";
      }
    },
    onOk() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + me.pathResourceAggiornaStato;
      let jsonData = JSON.stringify(me.items);
      axios.post(link, jsonData).then(() => {
        me.showmolalloading = false;
        me.loadData();
      });
    },
  },
};
</script>
