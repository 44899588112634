<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <div style="float: right">
                <print-component :reportName="reportName" :data="items"></print-component>
            </div>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-input v-model="filtro.codiceReparto" type="search" id="codice" :placeholder="getLabelTraduora('bancaEmatica.filter.lblDepartmentCode')"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-input  v-model="filtro.descrizioneReparto" type="search" id="nome" :placeholder="getLabelTraduora('bancaEmatica.filter.lblDepartmentName')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ getLabelTraduora("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ getLabelTraduora("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>

        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ getLabelTraduora("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" fixed stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(codiceReparto)="{ item }">
                        <a> {{ item.codiceReparto }} </a>
                    </template>
                    <template v-slot:cell(descrizioneReparto)="{ item }">
                        <router-link class="sa-edit-link" :to="'/bancaematicareparti/view/' + item.id"> {{ item.descrizioneReparto }} </router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ getLabelTraduora("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import PrintComponent from "../../utility/components/PrintComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins:[UtilityMixin],
    components: { PrintComponent, SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            pathResource: "/bancaematicareparti",
            reportName: "ReportRepartiBancaEmatica",
            showModalLoading: false,
            perPage: 10,
            currentPage: 1,
            linkedit:"bancaematicareparti",
            filter: null,
            id: "-1",
            filtro: { codiceReparto: null, descrizioneReparto:null },
            filterOn: [],
            fields: [
                {
                    label: this.getLabelTraduora("bancaEmatica.lblDepartmentCode"),
                    key: "codiceReparto",
                    sortable: true,
                    thStyle: "width: 3rem",
                },
                {
                    label: this.getLabelTraduora("bancaEmatica.lblDepartmentName"),
                    key: "descrizioneReparto",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                // { class: "sa-table-column-action-single", thStyle: "width: 10rem" ,key: "actions", label: "" },
            ],
            items: [],
           
        };
    },
    created: function() {},
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource+"?forPage="+me.perPage+"&page="+me.currentPage;
            me.isBusy = true;
            console.log();
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    // console.log(response.data.data)
                    me.items = response.data.data.list;
        })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceReparto);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onOk() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + me.pathResourceAggiornaStato;
            let jsonData = JSON.stringify(me.data);
            axios.post(link, jsonData).then(() => {
                me.showModalLoading = false;
                me.loadData();
            });
        },        
    },
};
</script>
